import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { createUTCDate } from "../../utils/date";
import {
  LIST_RENTALS,
  UPDATE_RENTAL,
  SET_RENTAL,
  SET_RENTALS,
  SAVE_RENTAL,
  REMOVE_RENTAL,
  LIST_RENTAL_CATEGORIES,
  SET_RENTAL_CATEGORIES,
} from "../actions/rentals/types";

const initialState = {
  isFetching: false,
  rentals: [],
  rentalDetail: {},
  rentalCategories: [],
};

const rentals = (state = initialState, action) => {
  switch (action.type) {
    case LIST_RENTALS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        rentals: action.rentals || state.rentals,
      };
    case LIST_RENTAL_CATEGORIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_RENTAL_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        rentalCategories: action.rentalCategories,
      };
    case REMOVE_RENTAL:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_RENTAL:
      return {
        ...state,
        rentalDetail: {
          ...state.rentalDetail,
          [action.field]: action.value,
          longDescription:
            action.field === "longDescriptionState"
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.rentalDetail.longDescription,
        },
      };
    case SET_RENTAL:
      return {
        ...state,
        isFetching: false,
        rentalDetail: action.rentalDetail,
      };
    case SET_RENTALS:
      return {
        ...state,
        isFetching: false,
        rentals: action.rentals,
      };
    case SAVE_RENTAL:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default rentals;
