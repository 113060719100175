const replaceOnlyAlpha = (s) => {
  return s.replace(/[0-9]/g, '');
};

const replaceSpecial = (s, dashes) => {
  s = s.replace(/[ÆÁÀÂÄǍĂĀÃÅǺĄÆǼǢ]/g, 'A');
  s = s.replace(/[Ɓ]/g, 'B');
  s = s.replace(/[ĆĊĈČÇ]/g, 'C');
  s = s.replace(/[ĎḌĐƊÐ]/g, 'D');
  s = s.replace(/[ÉÈĖÊËĚĔĒĘẸƎƏƐ]/g, 'E');
  s = s.replace(/[ĠĜǦĞĢƔ]/g, 'G');
  s = s.replace(/[ĤḤĦ]/g, 'H');
  s = s.replace(/[IÍÌİÎÏǏĬĪĨĮỊĲ]/g, 'I');
  s = s.replace(/[ĴĴ]/g, 'J');
  s = s.replace(/[ĶƘ]/g, 'K');
  s = s.replace(/[ĹĻŁĽĿ]/g, 'L');
  s = s.replace(/[NŃN̈ŇÑŅŊ]/g, 'N');
  s = s.replace(/[ÓÒÔÖǑŎŌÕŐỌØǾƠŒĥ]/g, 'O');
  s = s.replace(/[ŔŘŖ]/g, 'R');
  s = s.replace(/[ŚŜŠŞȘṢẞ]/g, 'S');
  s = s.replace(/[ŤŢṬŦ]/g, 'T');
  s = s.replace(/[ÚÙÛÜǓŬŪŨŰŮŲỤƯ]/g, 'U');
  s = s.replace(/[ẂẀŴẄ]/g, 'W');
  s = s.replace(/[ÝỲŶŸȲỸƳ]/g, 'Y');
  s = s.replace(/[ŹŻŽẒ]/g, 'Z');
  s = s.replace(/[ŚŜŠŞȘṢ]/g, 'J');
  s = dashes ? s.replace(/[^0-9A-Z -]/g, '') : s.replace(/[^0-9A-Z]/g, '');
  return s;
};

export const searchSafe = (unsafe, numbers, dashes) => {
  unsafe = unsafe ? unsafe : '';
  unsafe = unsafe.toUpperCase();
  if (dashes) {
    unsafe = unsafe.replace(/[ ]/g, '-');
  }
  if (!numbers) {
    unsafe = replaceOnlyAlpha(unsafe);
  }
  return replaceSpecial(unsafe, dashes);
};

export const hexToRGB = (hex) => {
  hex = hex?.length ? hex.replace('#', '') : '';
  hex = '0x' + hex;
  let r = (hex >> 16) & 0xff;
  let g = (hex >> 8) & 0xff;
  let b = hex & 0xff;
  return `rgb(${r}, ${g}, ${b})`;
};
