import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import './style.scss';
import { listBlogs, removeBlogItem } from '../../../redux/actions/blog';

const fields = [
  {
    title: 'Titel',
    prop: 'title',
  },
  {
    title: 'URL',
    prop: 'fullURL',
  },
  {
    title: 'Aangemaakt',
    prop: 'created',
    asDateTime: true,
    dateFormat: 'dd/MM/yyyy',
  },
];

const tableActions = ['edit', 'delete'];

class Blog extends Component {
  state = {};

  componentDidMount = () => {
    this.props.doListBlogs();
  };

  editBlog = (blog) => {
    return this.props.history.push(`/blog/detail/${blog._id}`);
  };

  deleteBlog = (blog) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveBlog(blog._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListBlogs();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { blogs } = this.props;
    return (
      <div>
        <StyledTable
          title="Blog artikels"
          fields={fields}
          bodyItems={blogs}
          actions={tableActions}
          addNew="Nieuw blog artikel"
          addNewLink="/blog/new"
          onEdit={this.editBlog}
          onDelete={this.deleteBlog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogs: state.blog.blogs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListBlogs: () => {
      dispatch(listBlogs());
    },
    doRemoveBlog: (id) => {
      return dispatch(removeBlogItem(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
