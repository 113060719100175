export const UPDATE_TEAMBUILDING = 'UPDATE_TEAMBUILDING';
export const SET_TEAMBUILDING = 'SET_TEAMBUILDING';
export const SET_TEAMBUILDINGS = 'SET_TEAMBUILDINGS';
export const LIST_TEAMBUILDING = 'LIST_TEAMBUILDING';
export const LOAD_TEAMBUILDING = 'LOAD_TEAMBUILDING';
export const SAVE_TEAMBUILDING = 'SAVE_TEAMBUILDING';
export const SAVE_ACTIVITY_CATEGORY = 'SAVE_ACTIVITY_CATEGORY';
export const SAVE_ACTIVITY_VALUE = 'SAVE_ACTIVITY_VALUE';
export const REMOVE_TEAMBUILDING = 'REMOVE_TEAMBUILDING';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const LIST_ACTIVITIES = 'LIST_ACTIVITIES';
export const LOAD_ACTIVITY = 'LOAD_ACTIVITY';
export const SAVE_ACTIVITY = 'SAVE_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const LIST_LOCATIONS = 'LIST_LOCATIONS';
export const LOAD_LOCATION = 'LOAD_LOCATION';
export const SAVE_LOCATION = 'SAVE_LOCATION';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';
export const LIST_ACTIVITY_CATEGORIES = 'LIST_ACTIVITY_CATEGORIES';
export const LIST_ACTIVITY_VALUES = 'LIST_ACTIVITY_VALUES';
export const SET_ACTIVITY_CATEGORIES = 'SET_ACTIVITY_CATEGORIES';
export const SET_ACTIVITY_VALUES = 'SET_ACTIVITY_VALUES';
