import {
  faUsersCog,
  faShieldAlt,
  faUsers,
  faBox,
  faStream,
  faFileImport,
  faDollarSign,
  faGripLines,
  faLayerGroup,
  faBoxes,
  faHome,
  faStar,
  faBaseballBall,
  faGamepad,
  faNewspaper,
  faChalkboardTeacher,
  faUserCheck,
  faClock,
  faPercentage,
  faPlay,
  faMapPin,
  faChartBar,
  faChartPie,
  faBlog,
} from '@fortawesome/free-solid-svg-icons';

const NavigationLinks = [
  {
    icon: faChartPie,
    color: '#f1cc05',
    title: 'Dashboard',
    link: '/dashboard',
  },
  {
    icon: faUsers,
    color: '#f1cc05',
    title: 'Gebruikers',
    link: '/users',
    roles: ['admin', 'jeppaadmin'],
  },
  /*{
    icon: faShieldAlt,
    color: "#4ba989",
    title: "Merken",
    link: "/brands",
  },
  {
    icon: faStream,
    color: "#f10550",
    title: "Categorieën",
    link: "/categories",
  },
  {
    icon: faBaseballBall,
    color: '#f17305',
    title: 'Kampen',
    link: '/camps',
  },*/
  {
    icon: faBaseballBall,
    color: '#f17305',
    title: 'Kampen',
    link: '/campsv2',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faClock,
    color: '#f17305',
    title: 'Kamp Data',
    link: '/campsdata',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faStar,
    color: '#e3d64b',
    title: 'Kamp Attributen',
    link: '/campfeatures',
    roles: ['admin', 'jeppaadmin'],
  },
  /*{
    icon: faFileImport,
    color: "#05f17f",
    title: "Importeren",
    link: "/import",
  },*/
  {
    icon: faBox,
    color: '#05f17f',
    title: 'Verhuur',
    link: '/rentals',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faUsers,
    color: '#4ba989',
    title: 'Teambuilding',
    link: '/teambuilding',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faPlay,
    color: '#4ba989',
    title: 'Teambuilding Activiteiten',
    link: '/teambuildingactivities',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faPercentage,
    color: '#059ef1',
    title: 'Kortingscodes',
    link: '/reductioncodes',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faDollarSign,
    color: '#059ef1',
    title: 'Inschrijvingen',
    link: '/registrations',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faGripLines,
    color: '#e84ddb',
    title: 'Content',
    link: '/content',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faLayerGroup,
    color: '#e84ddb',
    title: 'Contentgroepen',
    link: '/contentgroups',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faNewspaper,
    color: '#4ddbe8',
    title: 'Nieuwsberichten',
    link: '/news',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    icon: faBlog,
    color: '#4ddbe8',
    title: 'Blog',
    link: '/blog',
    roles: ['admin', 'jeppaadmin', 'blogcreator'],
  },
  {
    icon: faUsersCog,
    color: '#dd7e5e',
    title: 'Nieuwsbrief',
    link: '/newsletters',
    roles: ['admin', 'jeppaadmin'],
  },
  /*{
    icon: faGamepad,
    color: '#4ba989',
    title: 'Wedstrijd',
    link: '/contest',
  },*/
  {
    icon: faUsers,
    color: '#fc0352',
    title: 'ZS Gebruikers',
    link: '/zsusers',
    roles: ['admin', 'jeppaadmin', 'zscreator'],
  },
  {
    icon: faUserCheck,
    color: '#fc0352',
    title: 'ZS Inschrijvingen',
    link: '/zssubscriptions',
    roles: ['admin', 'jeppaadmin', 'zscreator'],
  },
  {
    icon: faChalkboardTeacher,
    color: '#fc0352',
    title: 'ZS Jeppa Leerkrachten',
    link: '/zsteachers',
    roles: ['admin', 'jeppaadmin', 'zscreator'],
  },
  {
    icon: faGamepad,
    color: '#fc45ff',
    title: 'App Bedrijven',
    link: '/appjeppacompanies',
    roles: ['admin', 'jeppaadmin', 'appcreator'],
  },
  {
    icon: faGamepad,
    color: '#fc45ff',
    title: 'App Spelen',
    link: '/appjeppagames',
    roles: ['admin', 'jeppaadmin', 'appcreator'],
  },
  {
    icon: faGamepad,
    color: '#fc45ff',
    title: 'App Inschrijvingen',
    link: '/appjepparegistrations',
    roles: ['admin', 'jeppaadmin', 'appcreator'],
  },
  {
    icon: faGamepad,
    color: '#fc45ff',
    title: 'App Scores',
    link: '/appjeppascores',
    roles: ['admin', 'jeppaadmin', 'appcreator'],
  },
];

export default NavigationLinks;
