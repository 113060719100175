import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import {
  resetBlogtem,
  saveBlogItem,
  showBlogItem,
  updateBlogItem,
} from '../../../redux/actions/blog';
import { searchSafe } from '../../../utils/text';

class BlogDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
  };

  componentDidMount = () => {
    if (this.state.id) {
      this.props.doShowBlog(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen blog artikel');
        this.props.history.push('/blogs');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.blogCopy
      ) {
        let newCopy = { ...this.props.location.state.blogCopy };
        newCopy.copyFromLanguage = JSON.parse(JSON.stringify(newCopy.language));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetBlog(newCopy);
      } else {
        this.props.doResetBlog();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw blog artikel niet op te slaan.',
      'success'
    );
    this.props.doUpdateBlog(
      mobile ? 'bannerImgMobile' : 'bannerImg',
      files[0].location
    );
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw artikel niet op te slaan.',
      'success'
    );
    this.props.doUpdateBlog('video', files[0].location);
  };

  onMainUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw artikel niet op te slaan.',
      'success'
    );
    this.props.doUpdateBlog('mainImg', files[0].location);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateBlog('longDescriptionState', editorState);
  };

  saveBlog = () => {
    const { _id, title, shortDescription, url } = this.props.blogDetail;
    if (
      !title ||
      title.length < 2 ||
      !shortDescription ||
      shortDescription.length < 2 ||
      !url ||
      url.length < 3
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld.',
        'error'
      );
    }
    this.props
      .doSaveBlog()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Artikel toegevoegd', 'success');
          return this.props.history.push(`/blog/detail/${result.blog._id}`);
        } else {
          return this.props.doSnack('Artikel bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewLanguage = (e) => {
    e.preventDefault();
    this.props.history.push('/blog/new', {
      blogCopy: { ...this.props.blogDetail },
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'blog_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  updateURLWithTitle = () => {
    const { blogDetail } = this.props;
    if (
      blogDetail.title &&
      blogDetail.title.length &&
      (!blogDetail.url || !blogDetail.url.length)
    ) {
      this.props.doUpdateBlog(
        'url',
        searchSafe(blogDetail.title, true, true).toLowerCase()
      );
    }
  };

  render() {
    const { blogDetail } = this.props;

    const longDescriptionState =
      blogDetail && blogDetail.longDescriptionState
        ? blogDetail.longDescriptionState
        : blogDetail.longDescription
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(blogDetail.longDescription)
            )
          )
        : EditorState.createEmpty();

    return (
      <div>
        <div className="blogdetail-root">
          <div className="blogdetail-root__header">
            <button className="btn btn-primary" onClick={() => this.saveBlog()}>
              Sla gegevens op
            </button>
          </div>
          <div className="blogdetail-root__form">
            <FormBlock
              title={`Details ${
                blogDetail.copyFromLanguage
                  ? `(Kopie van ${blogDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  <label>Titel *</label>
                  <input
                    id="title"
                    name="title"
                    value={blogDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateBlog('title', e.target.value)
                    }
                    onBlur={(e) => this.updateURLWithTitle()}
                  />
                  <label>Meta Titel</label>
                  <input
                    id="metaTitle"
                    name="metaTitle"
                    value={blogDetail.metaTitle || ''}
                    onChange={(e) =>
                      this.props.doUpdateBlog('metaTitle', e.target.value)
                    }
                  />
                  <label htmlFor="metaDescription">Meta Omschrijving</label>
                  <textarea
                    id="metaDescription"
                    name="metaDescription"
                    value={blogDetail.metaDescription || ''}
                    onChange={(e) =>
                      this.props.doUpdateBlog('metaDescription', e.target.value)
                    }
                    rows={4}
                  />
                  <label htmlFor="shortdescription">
                    Korte info voor blog overzicht
                  </label>
                  <textarea
                    id="shortdescription"
                    name="shortdescription"
                    value={blogDetail.shortDescription || ''}
                    onChange={(e) =>
                      this.props.doUpdateBlog(
                        'shortDescription',
                        e.target.value
                      )
                    }
                    rows={4}
                  />
                  <label>URL * (geen spaties of speciale tekens)</label>
                  <input
                    id="url"
                    name="url"
                    value={blogDetail.url || ''}
                    onChange={(e) =>
                      this.props.doUpdateBlog('url', e.target.value)
                    }
                  />
                </CustomForm>
              </form>
            </FormBlock>
            {blogDetail._id && (
              <FormBlock title="Nieuws gegevens">
                <form>
                  <CustomForm>
                    <label htmlFor="name">Blog artikel</label>
                    <Editor
                      editorState={longDescriptionState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="wysiwygeditor"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: this.uploadImageCallBack,
                          alt: { present: true, mandatory: false },
                          previewImage: true,
                          inputAccept: 'image/jpeg,image/jpg,image/png',
                        },
                      }}
                    />
                    <label htmlFor="ctaLabel">CTA Label</label>
                    <input
                      id="ctaLabel"
                      name="ctaLabel"
                      value={blogDetail.ctaLabel || ''}
                      onChange={(e) =>
                        this.props.doUpdateBlog('ctaLabel', e.target.value)
                      }
                    />
                    <label htmlFor="ctaLink">
                      CTA Link URL (vb. /teambuilding)
                    </label>
                    <input
                      id="ctaLink"
                      name="ctaLink"
                      value={blogDetail.ctaLink || ''}
                      onChange={(e) =>
                        this.props.doUpdateBlog(
                          'ctaLink',
                          e.target.value.trim()
                        )
                      }
                    />
                    <label>
                      Youtube Video ID (wat achter '/watch?v=' staat vb.
                      44KNdTRyAHo)
                    </label>
                    {blogDetail.youtubeID && (
                      <iframe
                        width="100%"
                        height="320"
                        src={`https://www.youtube.com/embed/${blogDetail.youtubeID}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                    <input
                      id="youtubeID"
                      name="youtubeID"
                      value={blogDetail.youtubeID || ''}
                      onChange={(e) =>
                        this.props.doUpdateBlog('youtubeID', e.target.value)
                      }
                    />
                    <label>Banner afbeelding detailpagina</label>
                    {blogDetail.bannerImg && (
                      <div>
                        <img
                          src={blogDetail.bannerImg}
                          width="auto"
                          style={{
                            maxHeight: 320,
                            marginBottom: 6,
                          }}
                          alt="head"
                        />
                      </div>
                    )}
                    <FileUpload
                      onUploadComplete={this.onHeaderUpload}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg, .svg, .webp"
                      prefix="news"
                    />
                    <label>Banner afbeelding detailpagina (MOBILE)</label>
                    {blogDetail.bannerImgMobile && (
                      <div>
                        <img
                          src={blogDetail.bannerImgMobile}
                          width="auto"
                          style={{
                            maxHeight: 320,
                            marginBottom: 6,
                          }}
                          alt="head"
                        />
                      </div>
                    )}
                    <FileUpload
                      onUploadComplete={(files) =>
                        this.onHeaderUpload(files, true)
                      }
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg, .svg, .webp"
                      prefix="news"
                    />
                    <label>Banner video detailpagina</label>
                    {blogDetail.video && (
                      <video width="100%" height="300" controls>
                        <source src={blogDetail.video} type="video/mp4" />
                      </video>
                    )}
                    <FileUpload
                      onUploadComplete={this.onVideoUpload}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept=".mp4"
                      prefix="news"
                      maxSizeKB={2000}
                    />
                    <label htmlFor="bannerTitle">Banner Titel</label>
                    <textarea
                      id="bannerTitle"
                      name="bannerTitle"
                      value={blogDetail.bannerTitle || ''}
                      onChange={(e) =>
                        this.props.doUpdateBlog('bannerTitle', e.target.value)
                      }
                      rows={3}
                    />
                    <label>Schuine hoofdafbeelding voor overzicht</label>
                    {blogDetail.mainImg && (
                      <img src={blogDetail.mainImg} width="320px" alt="main" />
                    )}
                    <FileUpload
                      onUploadComplete={(files) => this.onMainUpload(files)}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg"
                      prefix="rentals"
                    />
                  </CustomForm>
                </form>
              </FormBlock>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogDetail: state.blog.blogDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSaveBlog: () => {
      return dispatch(saveBlogItem());
    },
    doResetBlog: () => {
      dispatch(resetBlogtem());
    },
    doShowBlog: (id) => {
      return dispatch(showBlogItem(id));
    },
    doUpdateBlog: (field, val) => {
      dispatch(updateBlogItem(field, val));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
