export const UPDATE_ZSTEACHER = 'UPDATE_ZSTEACHER';
export const SET_ZSTEACHER = 'SET_ZSTEACHER';
export const SET_ZSTEACHERS = 'SET_ZSTEACHERS';
export const LIST_ZSTEACHERS = 'LIST_ZSTEACHERS';
export const LOAD_ZSTEACHER = 'LOAD_ZSTEACHER';
export const SAVE_ZSTEACHER = 'SAVE_ZSTEACHER';
export const LIST_ZSTEACHERSSCHOOLS = 'LIST_ZSTEACHERSSCHOOLS';
export const SAVE_ZSTEACHERSSCHOOL = 'SAVE_ZSTEACHERSSCHOOL';
export const SET_ZSTEACHERSSCHOOLS = 'SET_ZSTEACHERSSCHOOLS';
export const REMOVE_ZSTEACHER = 'REMOVE_ZSTEACHER';
