import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { showRegistration } from '../../../redux/actions/appjeppa';
class AppRegistrationDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newGroup: {},
  };

  componentDidMount = () => {
    if (this.state.id) {
      this.props.doShowRegistration(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen inschrijving');
        this.props.history.push('/appjepparegistrations');
      });
    } else {
      this.props.history.push('/appjepparegistrations');
    }
  };

  setPlayersInTeam = (theTeam) => {
    if (theTeam.pl2firstname) {
      let counter = 2;
      let allPlayers = [];
      //has other players
      let found = true;
      while (found) {
        if (theTeam[`pl${counter}firstname`]) {
          allPlayers.push(
            `${theTeam[`pl${counter}firstname`]} ${theTeam[`pl${counter}name`]}`
          );
          counter++;
        } else {
          found = false;
        }
      }
      return allPlayers;
    }
    return [];
  };

  render() {
    const { registrationDetail } = this.props;
    const playersInTeam = registrationDetail
      ? this.setPlayersInTeam(registrationDetail)
      : [];

    return (
      <div>
        <div className="registrationDetail-root">
          <div className="registrationDetail-root__form">
            <FormBlock
              title={`Details ${
                registrationDetail.copyFromLanguage
                  ? `(Kopie van ${registrationDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  <label>Teamnaam</label>
                  <input
                    id="teamname"
                    name="teamname"
                    value={registrationDetail.teamname || ''}
                    readOnly
                  />
                  <label>Groep</label>
                  <input
                    id="group"
                    name="group"
                    value={registrationDetail.group || ''}
                    readOnly
                  />
                  <label>Speler 1 voornaam</label>
                  <input
                    id="firstname"
                    name="firstname"
                    value={registrationDetail.firstname || ''}
                    readOnly
                  />
                  <label>Speler 1 naam</label>
                  <input
                    id="lastname"
                    name="lastname"
                    value={registrationDetail.lastname || ''}
                    readOnly
                  />
                  <label>Email</label>
                  <input
                    id="email"
                    name="email"
                    value={registrationDetail.email || ''}
                    readOnly
                  />
                  <label>Wachtwoord</label>
                  <input
                    id="originalPass"
                    name="originalPass"
                    value={registrationDetail.originalpass || ''}
                    readOnly
                  />
                  {playersInTeam.map((player, p) => (
                    <>
                      <label>Speler {p + 2}</label>
                      <input
                        id={`speler${p + 2}`}
                        name={`speler${p + 2}`}
                        value={player}
                        readOnly
                      />
                    </>
                  ))}
                </CustomForm>
              </form>
            </FormBlock>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationDetail: state.appjeppa.registrationDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doShowRegistration: (id) => {
      return dispatch(showRegistration(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRegistrationDetail);
