import draftToHtml from 'draftjs-to-html';
import {
  LIST_COMPANIES,
  UPDATE_COMPANY,
  SET_COMPANY,
  SET_COMPANIES,
  SAVE_COMPANY,
  REMOVE_COMPANY,
  LIST_GAMES,
  SET_GAMES,
  UPDATE_GAME,
  SET_GAME,
  SAVE_GAME,
  REMOVE_GAME,
  LIST_APP_REGISTRATIONS,
  SET_APP_REGISTRATIONS,
  REMOVE_APP_REGISTRTATION,
  REMOVE_APP_SCORES,
  LIST_APP_SCORES,
  SET_APP_SCORES,
  SET_REGISTRATION,
} from '../actions/appjeppa/types';
import { convertToRaw } from 'draft-js';

const initialState = {
  isFetching: false,
  companies: [],
  companyDetail: {},
  games: [],
  gameDetail: {},
  appRegistrations: [],
  appScores: [],
  registrationDetail: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case LIST_COMPANIES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        companies: action.companies || state.companies,
      };
    case LIST_APP_REGISTRATIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        appRegistrations: action.appRegistrations || state.appRegistrations,
      };
    case LIST_GAMES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        games: action.games || state.games,
      };
    case LIST_APP_SCORES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        appScores: action.appScores || state.appScores,
      };
    case REMOVE_COMPANY:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_GAME:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_APP_SCORES:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_APP_REGISTRTATION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        companyDetail: {
          ...state.companyDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.companyDetail.longDescription,
          welcomeText:
            action.field === 'welcomeDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.companyDetail.welcomeText,
        },
      };
    case UPDATE_GAME:
      return {
        ...state,
        gameDetail: {
          ...state.gameDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.gameDetail.longDescription,
        },
      };
    case SET_COMPANY:
      return {
        ...state,
        isFetching: false,
        companyDetail: action.companyDetail,
      };
    case SET_REGISTRATION:
      return {
        ...state,
        isFetching: false,
        registrationDetail: action.registrationDetail,
      };
    case SET_GAME:
      return {
        ...state,
        isFetching: false,
        gameDetail: action.gameDetail,
      };
    case SET_COMPANIES:
      return {
        ...state,
        isFetching: false,
        companies: action.companies,
      };
    case SET_GAMES:
      return {
        ...state,
        isFetching: false,
        games: action.games,
      };
    case SET_APP_REGISTRATIONS:
      return {
        ...state,
        isFetching: false,
        appRegistrations: action.appRegistrations,
      };
    case SET_APP_SCORES:
      return {
        ...state,
        isFetching: false,
        appScores: action.appScores,
      };
    case SAVE_COMPANY:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_GAME:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default brands;
