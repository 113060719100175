import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import './style.scss';
import {
  listActivities,
  removeActivity,
} from '../../../redux/actions/teambuilding';

const fields = [
  {
    title: 'Titel',
    prop: 'title',
  },
  {
    title: 'URL',
    prop: 'url',
  },
  {
    title: 'Stortering',
    prop: 'sortorder',
  },
];

const tableActions = ['edit', 'delete'];

class TeambuildingActivities extends Component {
  state = {};

  componentDidMount = () => {
    this.props.doListActivities();
  };

  editActivity = (activity) => {
    return this.props.history.push(
      `/teambuildingactivities/detail/${activity._id}`
    );
  };

  deleteActivity = (activity) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveActivity(activity._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListActivities();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { activities } = this.props;
    return (
      <div>
        <StyledTable
          title="Teambuilding Activiteiten"
          fields={fields}
          bodyItems={activities}
          actions={tableActions}
          addNew="Nieuwe activiteit"
          addNewLink="/teambuildingactivities/new"
          onEdit={this.editActivity}
          onDelete={this.deleteActivity}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activities: state.teambuilding.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListActivities: () => {
      dispatch(listActivities());
    },
    doRemoveActivity: (id) => {
      return dispatch(removeActivity(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeambuildingActivities);
