import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  LIST_BLOGS,
  UPDATE_BLOGITEM,
  SET_BLOGITEM,
  SET_BLOGS,
  SAVE_BLOGITEM,
  REMOVE_BLOGITEM,
} from '../actions/blog/types';

const initialState = {
  isFetching: false,
  blogs: [],
  blogDetail: {},
};

const blog = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BLOGS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        blogs: action.blogs || state.blogs,
      };
    case REMOVE_BLOGITEM:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_BLOGITEM:
      return {
        ...state,
        blogDetail: {
          ...state.blogDetail,
          [action.field]: action.value,
          longDescription:
            action.field === 'longDescriptionState'
              ? draftToHtml(convertToRaw(action.value.getCurrentContent()))
              : state.blogDetail.longDescription,
        },
      };
    case SET_BLOGITEM:
      return {
        ...state,
        isFetching: false,
        blogDetail: action.blogDetail,
      };
    case SET_BLOGS:
      return {
        ...state,
        isFetching: false,
        blogs: action.blogs,
      };
    case SAVE_BLOGITEM:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default blog;
