import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from '../../components/Loader';
import HashedRoute from '../../HashedRoute';
import CampsData from './CampsData';
import CampsDataDetail from './CampsDataDetail';
import CampsV2 from './CampsV2';
import CampV2Detail from './CampV2Detail';
import AppCompanies from './AppCompanies';
import AppCompanyDetail from './AppCompanyDetail';
import AppGames from './AppGames';
import AppGameDetail from './AppGameDetail';
import AppRegistrations from './AppRegistrations';
import AppScores from './AppScores';
import AppRegistrationDetail from './AppRegistrationDetail';
import TeambuildingActivities from './TeambuildingActivities';
import TeambuildingActivityDetail from './TeambuildingActivityDetail';
import TeambuildingLocations from './TeambuildingLocations';
import { getTokenUser } from '../../auth/auth';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import BlogDetail from './BlogDetail';
import Blog from './Blog';

const Users = lazy(() => import('./Users'));
const Dashboard = lazy(() => import('./Dashboard'));
const UsersDetail = lazy(() => import('./UsersDetail'));
const Content = lazy(() => import('./Content'));
const ContentDetail = lazy(() => import('./ContentDetail'));
const Contentgroups = lazy(() => import('./ContentGroups'));
const ContentgroupsDetail = lazy(() => import('./ContentGroupsDetail'));
const Camps = lazy(() => import('./Camps'));
const CampDetail = lazy(() => import('./CampDetail'));
const Rentals = lazy(() => import('./Rentals'));
const RentalDetail = lazy(() => import('./RentalDetail'));
const ProductFeatures = lazy(() => import('./ProductFeatures'));
const ProductFeatureDetail = lazy(() => import('./ProductFeatureDetail'));
const Newsletters = lazy(() => import('./Newsletters'));
const Registrations = lazy(() => import('./Registrations'));
const Contest = lazy(() => import('./Contest'));
const News = lazy(() => import('./News'));
const NewsDetail = lazy(() => import('./NewsDetail'));
const ZSUsers = lazy(() => import('./ZSUsers'));
const ZSUsersDetail = lazy(() => import('./ZSUsersDetail'));
const ZSSubscriptions = lazy(() => import('./ZSSubscriptions'));
const ZSSubscriptionDetail = lazy(() => import('./ZSSubscriptionDetail'));
const ZSTeachers = lazy(() => import('./ZSTeachers'));
const ZSTeachersDetail = lazy(() => import('./ZSTeachersDetail'));
const RegistrationDetail = lazy(() => import('./RegistrationDetail'));
const Teambuilding = lazy(() => import('./Teambuilding'));
const TeambuildingDetail = lazy(() => import('./TeambuildingDetail'));
const ReductionCodes = lazy(() => import('./ReductionCodes'));
const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    title: 'Startpagina',
  },
  {
    path: '/users',
    component: Users,
    title: 'Gebruikers',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    path: '/users/new',
    component: UsersDetail,
    title: 'Nieuwe Gebruiker',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    path: '/users/detail/:id',
    component: UsersDetail,
    title: 'Gebruiker Detail',
    roles: ['admin', 'jeppaadmin'],
  },
  {
    path: '/campfeatures',
    component: ProductFeatures,
    title: 'Kamp Attributen',
  },
  {
    path: '/campfeatures/new',
    component: ProductFeatureDetail,
    title: 'Nieuw Attribuut',
  },
  {
    path: '/campfeatures/detail/:id',
    component: ProductFeatureDetail,
    title: 'Attribuut Detail',
  },
  {
    path: '/camps',
    component: Camps,
    title: 'Kampen',
  },
  {
    path: '/campsv2',
    component: CampsV2,
    title: 'Kampen V2',
  },
  {
    path: '/campsdata',
    component: CampsData,
    title: 'Kamp Data',
  },
  {
    path: '/campsdata/new',
    component: CampsDataDetail,
    title: 'Nieuw Kamp Data',
  },
  {
    path: '/campsdata/detail/:id',
    component: CampsDataDetail,
    title: 'Kamp Data Detail',
  },
  {
    path: '/camps/new',
    component: CampDetail,
    title: 'Nieuw Kamp',
  },
  {
    path: '/camps/detail/:id',
    component: CampDetail,
    title: 'Kamp Detail',
  },
  {
    path: '/campsv2/new',
    component: CampV2Detail,
    title: 'Nieuw Kamp V2',
  },
  {
    path: '/campsv2/detail/:id',
    component: CampV2Detail,
    title: 'Kamp Detail V2',
  },
  {
    path: '/rentals',
    component: Rentals,
    title: 'Verhuurmaterialen',
  },
  {
    path: '/rentals/new',
    component: RentalDetail,
    title: 'Nieuwe Verhuur',
  },
  {
    path: '/rentals/detail/:id',
    component: RentalDetail,
    title: 'Verhuur Detail',
  },
  {
    path: '/teambuilding',
    component: Teambuilding,
    title: 'Teambuilding',
  },
  {
    path: '/teambuilding/new',
    component: TeambuildingDetail,
    title: 'Nieuwe Teambuilding',
  },
  {
    path: '/teambuilding/detail/:id',
    component: TeambuildingDetail,
    title: 'Teambuilding Detail',
  },
  {
    path: '/teambuildingactivities',
    component: TeambuildingActivities,
    title: 'Teambuilding Activiteiten',
  },
  {
    path: '/teambuildingactivities/new',
    component: TeambuildingActivityDetail,
    title: 'Nieuwe Activiteit',
  },
  {
    path: '/teambuildingactivities/detail/:id',
    component: TeambuildingActivityDetail,
    title: 'Activiteit Detail',
  },
  {
    path: '/content',
    component: Content,
    title: 'Content',
  },
  {
    path: '/content/new',
    component: ContentDetail,
    title: 'Nieuwe Content',
  },
  {
    path: '/content/detail/:id',
    component: ContentDetail,
    title: 'Content Detail',
  },
  {
    path: '/contentgroups',
    component: Contentgroups,
    title: 'Contentgroepen',
  },
  {
    path: '/contentgroups/new',
    component: ContentgroupsDetail,
    title: 'Nieuwe Contentgroep',
  },
  {
    path: '/contentgroups/detail/:id',
    component: ContentgroupsDetail,
    title: 'Contentgroep Detail',
  },
  {
    path: '/news',
    component: News,
    title: 'Nieuwsberichten',
  },
  {
    path: '/news/new',
    component: NewsDetail,
    title: 'Nieuw Nieuwsbericht',
  },
  {
    path: '/news/detail/:id',
    component: NewsDetail,
    title: 'Nieuwsbericht Detail',
  },
  {
    path: '/blog',
    component: Blog,
    title: 'Blog artikels',
  },
  {
    path: '/blog/new',
    component: BlogDetail,
    title: 'Nieuw blog artikel',
  },
  {
    path: '/blog/detail/:id',
    component: BlogDetail,
    title: 'Blog artikel detail',
  },
  {
    path: '/newsletters',
    component: Newsletters,
    title: 'Inschrijvingen Nieuwsbrief',
  },
  {
    path: '/reductioncodes',
    component: ReductionCodes,
    title: 'Kortingscodes',
  },
  {
    path: '/registrations',
    component: Registrations,
    title: 'Inschrijvingen',
  },
  {
    path: '/registrations/detail/:id',
    component: RegistrationDetail,
    title: 'Detail Inschrijving',
  },
  {
    path: '/contest',
    component: Contest,
    title: 'Wedstrijd',
  },
  {
    path: '/zsusers',
    component: ZSUsers,
    title: 'Zomerscholen Gebruikers',
  },
  {
    path: '/zsusers/new',
    component: ZSUsersDetail,
    title: 'Nieuwe Gebruiker Zomerschool',
  },
  {
    path: '/zsusers/detail/:id',
    component: ZSUsersDetail,
    title: 'Detail Gebruiker Zomerschool',
  },
  {
    path: '/zssubscriptions',
    component: ZSSubscriptions,
    title: 'Zomerscholen Inschrijvingen',
  },
  {
    path: '/zssubscriptions/new',
    component: ZSSubscriptionDetail,
    title: 'Nieuwe Zomerschool Inschrijving',
  },
  {
    path: '/zssubscriptions/detail/:id',
    component: ZSSubscriptionDetail,
    title: 'Detail Inschrijving Zomerschool',
  },
  {
    path: '/zsteachers',
    component: ZSTeachers,
    title: 'Zomerscholen Leekrachten Jeppa',
  },
  {
    path: '/zsteachers/new',
    component: ZSTeachersDetail,
    title: 'Nieuwe Zomerschool Leerkracht',
  },
  {
    path: '/zsteachers/detail/:id',
    component: ZSTeachersDetail,
    title: 'Detail Leerkracht Zomerschool',
  },
  {
    path: '/appjeppacompanies',
    component: AppCompanies,
    title: 'Jeppa App Bedrijven',
  },
  {
    path: '/appjeppacompanies/new',
    component: AppCompanyDetail,
    title: 'Nieuw App Bedrijf',
  },
  {
    path: '/appjeppacompanies/detail/:id',
    component: AppCompanyDetail,
    title: 'Detail App Bedrijf',
  },
  {
    path: '/appjeppagames',
    component: AppGames,
    title: 'Jeppa App Spelen',
  },
  {
    path: '/appjeppagames/new',
    component: AppGameDetail,
    title: 'Nieuw App Spel',
  },
  {
    path: '/appjeppagames/detail/:id',
    component: AppGameDetail,
    title: 'Detail App Spel',
  },
  {
    path: '/appjepparegistrations',
    component: AppRegistrations,
    title: 'Jeppa App Inschrijvingen',
  },
  {
    path: '/appjepparegistrations/detail/:id',
    component: AppRegistrationDetail,
    title: 'Detail App Inschrijving',
  },
  {
    path: '/appjeppascores',
    component: AppScores,
    title: 'Jeppa App Scores',
  },
];

const Router = (props) => {
  const createRoute = (path, Component, roles, index) => {
    const user = getTokenUser();
    return (
      <Route
        exact
        key={index}
        path={path}
        render={(props) =>
          !roles?.length || roles.includes(user.role) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/dashboard',
              }}
            />
          )
        }
      />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((item, i) => {
          return createRoute(item.path, item.component, item.roles, i);
        })}
        <HashedRoute path="/" component={Users} />
      </Switch>
    </Suspense>
  );
};

export default Router;

export const getRouteTitle = (path) => {
  for (var i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      path.indexOf(route.pathForTitle ? route.pathForTitle : route.path) === 0
    ) {
      return route.title;
    }
  }
  return '';
};
