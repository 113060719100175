import {
  UPDATE_ZSSUBSCRIPTION,
  SET_ZSSUBSCRIPTION,
  SET_ZSSUBSCRIPTIONS,
  LIST_ZSSUBSCRIPTIONS,
  SAVE_ZSSUBSCRIPTION,
  LOAD_ZSSUBSCRIPTION,
  SAVE_ZSSUBSCRIPTIONSSCHOOL,
  SET_ZSSUBSCRIPTIONSSCHOOLS,
  LIST_ZSSUBSCRIPTIONSSCHOOLS,
  REMOVE_ZSSUBSCRIPTION,
} from '../actions/zssubscriptions/types';

export const initialState = {
  isFetching: false,
  subscriptions: [],
  subscriptionDetail: {},
  schools: [],
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ZSSUBSCRIPTION:
      return {
        ...state,
        subscriptionDetail: {
          ...state.subscriptionDetail,
          [action.field]: action.value,
        },
      };
    case REMOVE_ZSSUBSCRIPTION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_ZSSUBSCRIPTION:
      return {
        ...state,
        isFetching: false,
        subscriptionDetail: action.subscriptionDetail,
      };
    case SET_ZSSUBSCRIPTIONS:
      return {
        ...state,
        isFetching: false,
        subscriptions: action.subscriptions,
      };
    case SET_ZSSUBSCRIPTIONSSCHOOLS:
      return {
        ...state,
        isFetching: false,
        schools: action.schools,
      };
    case SAVE_ZSSUBSCRIPTION:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_ZSSUBSCRIPTIONSSCHOOL:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSSUBSCRIPTIONS:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSSUBSCRIPTIONSSCHOOLS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_ZSSUBSCRIPTION:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default subscriptions;
