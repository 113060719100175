import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { AppName, appVersion } from '../../config';
import NavigationLinks from '../../navigation/NavigationLinks';
import { getTokenUser } from '../../auth/auth';

const Navigation = () => {
  const user = getTokenUser();
  return (
    <div className={`navigation-root open`}>
      <div className="navigation-root__head">
        <NavLink className="navigation-root__head__link" to="/">
          <span>
            {AppName}
            <br />
            <span className="navigation-root__head__link__version">
              v{appVersion}
            </span>
          </span>
        </NavLink>
      </div>
      <nav className="navigation-root__nav">
        <ul>
          {NavigationLinks.map((link, i) => {
            return !link.roles || link.roles.indexOf(user.role) !== -1 ? (
              <li key={i}>
                <NavLink
                  to={link.link}
                  className="navigation-root__nav__link"
                  activeClassName="navigation-root__nav__link__active"
                >
                  <span className="navigation-root__nav__link__icon">
                    <FontAwesomeIcon icon={link.icon} color={link.color} />
                  </span>
                  <span className="navigation-root__nav__link__title">
                    {link.title}
                  </span>
                </NavLink>
              </li>
            ) : null;
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
