import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import StyledTable, { tableFilter } from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import {
  listAppRegistrations,
  listAppScores,
  removeAppRegistration,
  removeAppScore,
} from '../../../redux/actions/appjeppa';
import {
  removeFiltersWithProp,
  updateFiltersWithProp,
} from '../../../utils/object';

const fields = [
  {
    title: 'Spel',
    prop: 'company.title',
  },
  {
    title: 'Team',
    prop: 'team.name',
  },
  {
    title: 'Spel',
    prop: 'game.title',
  },
  {
    title: 'Score',
    prop: 'enteredScore',
  },
  {
    title: 'Punten',
    prop: 'resultScore',
  },
];

const tableActions = ['delete'];

class AppScores extends Component {
  state = {
    groups: undefined,
    activeFilters: {},
  };

  componentDidMount = () => {
    this.props.doListAppScores();
  };

  deleteScore = (score) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveAppScore(score._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListAppScores();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { appScores } = this.props;
    const { activeFilters } = this.state;
    let scoresFiltered = Object.keys(activeFilters).length
      ? tableFilter(appScores, activeFilters)
      : appScores;
    return (
      <div>
        <StyledTable
          title="Jeppa App Scores"
          fields={fields}
          bodyItems={scoresFiltered}
          actions={tableActions}
          onDelete={this.deleteScore}
          filter={[
            {
              name: 'Bedrijf',
              prop: 'company.name',
              type: 'text',
            },
            {
              name: 'Team',
              prop: 'team.name',
              type: 'text',
            },
            {
              name: 'Spel',
              prop: 'game.title',
              type: 'text',
            },
          ]}
          onFilter={(type, prop, value) => {
            return prop && value?.length
              ? this.setState({
                  activeFilters: updateFiltersWithProp(
                    this.state.activeFilters,
                    prop,
                    value
                  ),
                })
              : this.setState({
                  activeFilters: {
                    ...removeFiltersWithProp(this.state.activeFilters, prop),
                  },
                });
          }}
          filtersActive={activeFilters}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.appjeppa.isFetching,
    appScores: state.appjeppa.appScores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListAppScores: () => {
      dispatch(listAppScores());
    },
    doRemoveAppScore: (id) => {
      return dispatch(removeAppScore(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppScores);
