import {
  ADD_REDUCTIONCODE,
  LIST_ACTIVE_CAMPS,
  LIST_CONTEST,
  LIST_REDUCTIONCODES,
  LIST_REGISTRATIONS,
  LOAD_REGISTRATION,
  REMOVE_REDUCTIONCODE,
  REMOVE_REGISTRATION,
  SAVE_REGISTRATION,
  SET_ACTIVE_CAMPS,
  SET_CONTEST,
  SET_REDUCTIONCODES,
  SET_REGISTRATION,
  SET_REGISTRATIONS,
  UPDATE_REGISTRATION,
} from '../actions/registrations/types';

const initialState = {
  isFetching: false,
  registrations: [],
  registrationDetail: {},
  camps: [],
  contest: [],
  reductionCodes: [],
};

const registrations = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REGISTRATION:
      return {
        ...state,
        registrationDetail: {
          ...state.registrationDetail,
          [action.field]: action.value,
        },
      };
    case SET_REGISTRATION:
      return {
        ...state,
        isFetching: false,
        registrationDetail: action.registrationDetail,
      };
    case LIST_REGISTRATIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        registrations: action.registrations || state.registrations,
      };
    case LIST_REDUCTIONCODES:
      return {
        ...state,
        isFetching: action.isFetching || false,
        reductionCodes: action.reductionCodes || state.reductionCodes,
      };
    case REMOVE_REGISTRATION:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case REMOVE_REDUCTIONCODE:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_REGISTRATIONS:
      return {
        ...state,
        isFetching: false,
        registrations: action.registrations,
      };
    case SET_REDUCTIONCODES:
      return {
        ...state,
        isFetching: false,
        reductionCodes: action.reductionCodes,
      };
    case LIST_CONTEST:
      return {
        ...state,
        isFetching: action.isFetching || false,
        contest: action.contest || state.contest,
      };
    case SET_CONTEST:
      return {
        ...state,
        isFetching: false,
        contest: action.contest,
      };
    case SET_ACTIVE_CAMPS:
      return {
        ...state,
        isFetching: false,
        camps: action.camps,
      };
    case SAVE_REGISTRATION:
      return { ...state, isFetching: action.isFetching || false };
    case ADD_REDUCTIONCODE:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ACTIVE_CAMPS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_REGISTRATION:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default registrations;
