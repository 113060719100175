import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import {
  listAppRegistrations,
  removeAppRegistration,
  uploadTeams,
} from '../../../redux/actions/appjeppa';
import {
  removeFiltersWithProp,
  removePropFromObject,
  updateFiltersWithProp,
} from '../../../utils/object';
import { tableFilter } from '../../../components/StyledTable';

const fields = [
  {
    title: 'Bedrijf',
    prop: 'company.name',
  },
  {
    title: 'Spel',
    prop: 'company.title',
  },
  {
    title: 'Email',
    prop: 'email',
  },
  {
    title: 'Team',
    prop: 'teamname',
  },
  {
    title: 'Voornaam hoofdspeler',
    prop: 'firstname',
  },
  {
    title: 'Naam hoofdspeler',
    prop: 'lastname',
  },
  {
    title: 'Hoofdaccount',
    prop: 'isMainAccount',
    boolAsCheck: true,
  },
];

const tableActions = ['edit', 'delete'];

class AppRegistrations extends Component {
  state = {
    groups: undefined,
    activeFilters: {},
  };

  componentDidMount = () => {
    this.props.doListAppRegistrations();
  };

  viewRegistration = (reg) => {
    return this.props.history.push(`/appjepparegistrations/detail/${reg._id}`);
  };

  deleteRegistration = (registration) => {
    const mainAccountText = registration.isMainAccount
      ? 'Dit is een hoofdaccount, indien deze nog gelinkte accounts heeft zullen deze ook verwijderd worden evenals alle scores.'
      : '';
    swal({
      title: 'Item verwijderen',
      text:
        'Bent u zeker dat u het team en bijhorende scores wil verwijderen? ' +
        mainAccountText,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveAppRegistration(registration._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListAppRegistrations();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  uploadRegistrations = (data) => {
    let teamsData = {};
    if (!data?.length) {
      return swal(
        'Geen deelnemers',
        'Er konden geen deelnemers ingelezen worden.',
        'error'
      );
    }
    let participants = 0;
    for (var d = 0; d < data.length; d++) {
      var dataItem = data[d];
      if (
        dataItem['Bedrijfsurl'] &&
        dataItem['Groep'] &&
        dataItem['Voornaam'] &&
        dataItem['Naam'] &&
        dataItem['Teamnaam']
      ) {
        const companyURL = dataItem['Bedrijfsurl'];
        const teamName = dataItem['Teamnaam'];
        const groupName = dataItem['Groep'];
        if (!teamsData[teamName]) {
          teamsData[teamName] = {
            companyurl: companyURL,
            teamname: teamName,
            group: groupName,
            firstname: null,
            lastname: null,
            email: null,
            extraPlayers: 0,
          };
        }
        const firstName = dataItem['Voornaam'];
        const name = dataItem['Naam'];
        const email = dataItem['Email'];
        const password = dataItem['Wachtwoord'];
        if (email?.length) {
          //this is a captain
          if (teamsData[teamName].email) {
            return swal(
              'Probleem',
              `Er is reeds een kaptein e-mailadres ${teamsData[teamName].email} voor team ${teamName} dus het adres ${email} kan niet meer gebruikt worden. Controleer de lijst op fouten.`,
              'error'
            );
          }
          if (!password?.length) {
            return swal(
              'Probleem',
              `Op de lijn met email ${email} wordt een wachtwoord verwacht maar is niet gegeven.`,
              'error'
            );
          }
          teamsData[teamName].email = email;
          teamsData[teamName].password = password;
          teamsData[teamName].firstname = firstName;
          teamsData[teamName].lastname = name;
          participants++;
        } else {
          const extraPlayerNumber = teamsData[teamName].extraPlayers + 2;
          teamsData[teamName].extraPlayers++;
          teamsData[teamName][`pl${extraPlayerNumber}firstname`] = firstName;
          teamsData[teamName][`pl${extraPlayerNumber}name`] = name;
          participants++;
        }
      }
    }
    let teams = Object.getOwnPropertyNames(teamsData);
    let teamUpload = [];
    if (!teams?.length) {
      return swal(
        'Probleem',
        'Er konden geen teams aangemaakt worden uit deze lijst',
        'error'
      );
    }
    for (let t = 0; t < teams?.length; t++) {
      const teamFullName = teams[t];
      const theTeam = teamsData[teamFullName];
      if (!theTeam.email || !theTeam.password) {
        return swal(
          'Probleem',
          `Team ${teamFullName} heeft geen e-mailadres en wachtwoord voor de kapitein. Inladen van deelnemers mislukt.`,
          'error'
        );
      }
      teamUpload.push(theTeam);
    }
    swal({
      title: 'Deelnemers opladen',
      text: `Er kunnen ${participants} deelnemer(s) in ${teams.length} team(s) opgeladen worden. Wilt u doorgaan? De huidige deelnemers en scores voor dit bedrijf worden gewist.`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.setState({ uploading: true });
        this.props
          .doUploadTeams(teamUpload)
          .then(() => {
            this.setState({ uploading: false });
            this.props.doSnack(
              'Teams en deelnemers werden aangemaakt',
              'success'
            );
            this.props.doListAppRegistrations();
          })
          .catch(() => {
            this.setState({ uploading: false });
            this.props.doSnack('Probleem bij aanmaken van teams');
          });
      }
    });
  };

  render() {
    const { appRegistrations } = this.props;
    const { activeFilters, uploading } = this.state;
    let registrationsFiltered = Object.keys(activeFilters).length
      ? tableFilter(appRegistrations, activeFilters)
      : appRegistrations;
    return (
      <div>
        <StyledTable
          title="Jeppa App Inschrijvingen"
          fields={fields}
          bodyItems={registrationsFiltered}
          actions={tableActions}
          onDelete={this.deleteRegistration}
          onEdit={this.viewRegistration}
          csvUpload={true}
          onUpload={this.uploadRegistrations}
          csvUploading={uploading}
          filter={[
            {
              name: 'Bedrijf',
              prop: 'company.name',
              type: 'text',
            },
            {
              name: 'Email',
              prop: 'email',
              type: 'text',
            },
            {
              name: 'Team',
              prop: 'teamname',
              type: 'text',
            },
          ]}
          onFilter={(type, prop, value) => {
            return prop && value?.length
              ? this.setState({
                  activeFilters: updateFiltersWithProp(
                    this.state.activeFilters,
                    prop,
                    value
                  ),
                })
              : this.setState({
                  activeFilters: {
                    ...removeFiltersWithProp(this.state.activeFilters, prop),
                  },
                });
          }}
          filtersActive={activeFilters}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.appjeppa.isFetching,
    appRegistrations: state.appjeppa.appRegistrations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListAppRegistrations: () => {
      dispatch(listAppRegistrations());
    },
    doRemoveAppRegistration: (id) => {
      return dispatch(removeAppRegistration(id));
    },
    doUploadTeams: (teams) => {
      return dispatch(uploadTeams(teams));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRegistrations);
