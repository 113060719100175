export const UPDATE_ZSUSER = 'UPDATE_ZSUSER';
export const SET_ZSUSER = 'SET_ZSUSER';
export const SET_ZSUSERS = 'SET_ZSUSERS';
export const LIST_ZSUSERS = 'LIST_ZSUSERS';
export const LOAD_ZSUSER = 'LOAD_ZSUSER';
export const SAVE_ZSUSER = 'SAVE_ZSUSER';
export const LIST_ZSUSERSSCHOOLS = 'LIST_ZSUSERSSCHOOLS';
export const SAVE_ZSUSERSSCHOOL = 'SAVE_ZSUSERSSCHOOL';
export const SET_ZSUSERSSCHOOLS = 'SET_ZSUSERSSCHOOLS';
export const REMOVE_ZSUSER = 'REMOVE_ZSUSER';
