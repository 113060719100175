import fetchAxios from '../../middlewares/axios';
import {
  LIST_BLOGS,
  LOAD_BLOGITEM,
  SET_BLOGS,
  SET_BLOGITEM,
  UPDATE_BLOGITEM,
  SAVE_BLOGITEM,
  REMOVE_BLOGITEM,
} from './types';
import { ROOT_URL_PROTECTED } from '../../../config/axios';

export const listBlogs = () => {
  return (dispatch) => {
    dispatch({ type: LIST_BLOGS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/blog/`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.blogs
          ) {
            dispatch({
              type: SET_BLOGS,
              blogs: response.data.blogs,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_BLOGS });
          reject({ error: e });
        });
    });
  };
};

export const showBlogItem = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_BLOGITEM, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/blog/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.blog
          ) {
            dispatch({
              type: SET_BLOGITEM,
              blogDetail: response.data.blog,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_BLOGITEM });
          reject({ error: e });
        });
    });
  };
};

export const saveBlogItem = () => {
  return (dispatch, getState) => {
    const { blog } = getState();
    const { longDescriptionState, ...rest } = blog.blogDetail;
    dispatch({ type: SAVE_BLOGITEM, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/blog/update`,
        {
          blog: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.blog
          ) {
            dispatch({
              type: SET_BLOGITEM,
              blogDetail: response.data.blog,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_BLOGITEM });
          reject({ error: e });
        });
    });
  };
};

export const removeBlogItem = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_BLOGITEM, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/blog/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_BLOGITEM,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_BLOGITEM });
          reject({ error: e });
        });
    });
  };
};

export const updateBlogItem = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BLOGITEM,
      field,
      value,
    });
  };
};

export const resetBlogtem = () => {
  return (dispatch) => {
    dispatch({
      type: SET_BLOGITEM,
      blogDetail: {},
    });
  };
};
