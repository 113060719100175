import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import './style.scss';
import {
  listLocations,
  removeLocation,
} from '../../../redux/actions/teambuilding';

const fields = [
  {
    title: 'Naam',
    prop: 'name',
  },
  {
    title: 'URL',
    prop: 'url',
  },
  {
    title: 'Stortering',
    prop: 'sortorder',
  },
];

const tableActions = ['edit', 'delete'];

class TeambuildingLocations extends Component {
  state = {};

  componentDidMount = () => {
    this.props.doListLocations();
  };

  editLocation = (location) => {
    return this.props.history.push(
      `/teambuildinglocations/detail/${location._id}`
    );
  };

  deleteLocation = (location) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveLocation(location._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListLocations();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { locations } = this.props;
    return (
      <div>
        <StyledTable
          title="Teambuilding Locaties"
          fields={fields}
          bodyItems={locations}
          actions={tableActions}
          addNew="Nieuwe locatie"
          addNewLink="/teambuildinglocations/new"
          onEdit={this.editLocation}
          onDelete={this.deleteLocation}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.teambuilding.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListLocations: () => {
      dispatch(listLocations());
    },
    doRemoveLocation: (id) => {
      return dispatch(removeLocation(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeambuildingLocations);
