import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import { listCompanies, removeCompany } from '../../../redux/actions/appjeppa';

const fields = [
  {
    title: 'Bedrijfsnaam',
    prop: 'name',
  },
  {
    title: 'URL',
    prop: 'url',
  },
  {
    title: 'Event titel',
    prop: 'title',
  },
  {
    title: 'Aantal inschrijvingen',
    prop: 'numberOfRegistrations',
  },
];

const tableActions = ['edit', 'delete'];

class AppCompanies extends Component {
  state = {
    groups: undefined,
  };

  componentDidMount = () => {
    this.props.doListCompanies();
  };

  editCompany = (company) => {
    return this.props.history.push(`/appjeppacompanies/detail/${company._id}`);
  };

  deleteCompany = (company) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveCompany(company._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListCompanies();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { companies } = this.props;
    return (
      <div>
        <StyledTable
          title="Jeppa App Bedrijven"
          fields={fields}
          bodyItems={companies}
          addNew="Nieuw bedrijf"
          addNewLink="/appjeppacompanies/new"
          actions={tableActions}
          onEdit={this.editCompany}
          onDelete={this.deleteCompany}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.appjeppa.isFetching,
    companies: state.appjeppa.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListCompanies: () => {
      dispatch(listCompanies());
    },
    doRemoveCompany: (id) => {
      return dispatch(removeCompany(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCompanies);
