import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import { listGames, removeGame } from '../../../redux/actions/appjeppa';

const fields = [
  {
    title: 'Spelnaam',
    prop: 'titleCMS',
  },
  {
    title: 'URL',
    prop: 'url',
  },
  {
    title: 'Type',
    prop: 'type',
  },
  {
    title: 'Geen score',
    prop: 'disableScore',
    boolAsCheck: true,
  },
];

const tableActions = ['edit', 'delete'];

class AppCompanies extends Component {
  state = {
    groups: undefined,
  };

  componentDidMount = () => {
    this.props.doListGames();
  };

  editGame = (game) => {
    return this.props.history.push(`/appjeppagames/detail/${game._id}`);
  };

  deleteGame = (game) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het spel wil verwijderen? Indien het gekoppeld is bij één of meerdere bedrijven zal het spel ook daar verwijderd worden van de lijst met spelen.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveGame(game._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListGames();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  render() {
    const { games } = this.props;
    return (
      <div>
        <StyledTable
          title="Jeppa App Spelen"
          fields={fields}
          bodyItems={games}
          addNew="Nieuw Spel"
          addNewLink="/appjeppagames/new"
          actions={tableActions}
          onEdit={this.editGame}
          onDelete={this.deleteGame}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.appjeppa.isFetching,
    games: state.appjeppa.games,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListGames: () => {
      dispatch(listGames());
    },
    doRemoveGame: (id) => {
      return dispatch(removeGame(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCompanies);
