export const UPDATE_ZSSUBSCRIPTION = 'UPDATE_ZSSUBSCRIPTION';
export const SET_ZSSUBSCRIPTION = 'SET_ZSSUBSCRIPTION';
export const SET_ZSSUBSCRIPTIONS = 'SET_ZSSUBSCRIPTIONS';
export const LIST_ZSSUBSCRIPTIONS = 'LIST_ZSSUBSCRIPTIONS';
export const LOAD_ZSSUBSCRIPTION = 'LOAD_ZSSUBSCRIPTION';
export const SAVE_ZSSUBSCRIPTION = 'SAVE_ZSSUBSCRIPTION';
export const LIST_ZSSUBSCRIPTIONSSCHOOLS = 'LIST_ZSSUBSCRIPTIONSSCHOOLS';
export const SAVE_ZSSUBSCRIPTIONSSCHOOL = 'SAVE_ZSSUBSCRIPTIONSSCHOOL';
export const SET_ZSSUBSCRIPTIONSSCHOOLS = 'SET_ZSSUBSCRIPTIONSSCHOOLS';
export const REMOVE_ZSSUBSCRIPTION = 'REMOVE_ZSSUBSCRIPTION';
