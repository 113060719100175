import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import {
  listCampsV2,
  listRegistrations,
  mailWaitingList,
  resetCampData,
  saveCampData,
  showCampData,
  updateCampData,
} from '../../../redux/actions/camps';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import { listFeatures } from '../../../redux/actions/productfeatures';
import { faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CampDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newCategory: {},
    newFeature: {},
    campRegistrations: [],
    regEmailList: undefined,
  };

  componentDidMount = () => {
    this.props.doListCampsV2();
    this.props.doListProductFeatures();

    if (this.state.id) {
      this.props.doShowCamp(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen kamp');
        this.props.history.push('/campsdata');
      });
      this.loadRegistrations(this.state.id);
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.campCopy
      ) {
        let newCopy = { ...this.props.location.state.campCopy };
        newCopy.copyFromCamp = JSON.parse(JSON.stringify(newCopy._id));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetCamp(newCopy);
      } else {
        this.props.doResetCamp();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp(
      mobile ? 'bannerImgMobile' : 'bannerImg',
      files[0].location
    );
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw verhuur niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('video', files[0].location);
  };

  onMainUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('mainImg', files[0].location);
  };

  onPDFUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('registrationPDF', files[0].location);
  };

  onCarrouselImgsComplete = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp niet op te slaan.',
      'success'
    );
    const imgs = files.map((file) => file.location);
    this.props.doUpdateCamp('carrouselImgs', imgs);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateCamp('longDescriptionState', editorState);
  };

  saveCamp = () => {
    const { campsv2 } = this.props;
    const {
      _id,
      campid,
      subTitleHome,
      price,
      address,
      dateFrom,
      dateTo,
      ageFrom,
      ageTo,
      maxRegistrations,
      campHalfDay,
      priceHalfDay,
    } = this.props.campDetail;
    if (
      !campid ||
      !subTitleHome ||
      subTitleHome.length < 2 ||
      !price ||
      !address ||
      address.length < 2 ||
      !dateFrom ||
      dateFrom.length < 2 ||
      !dateTo ||
      dateTo.length < 2 ||
      !ageFrom ||
      !ageTo ||
      !maxRegistrations
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld. Controleer de verplichte velden met *.',
        'error'
      );
    }
    if (campHalfDay && !priceHalfDay) {
      return this.props.doSnack(
        'De prijs voor een kamp met halve dag werd niet correct ingevuld.',
        'error'
      );
    }
    let theCamp = undefined;
    for (let c = 0; c < campsv2.length; c++) {
      if (campsv2[c]._id === campid) {
        theCamp = JSON.parse(JSON.stringify(campsv2[c]));
      }
    }
    this.props
      .doSaveCamp(theCamp)
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Kamp toegevoegd', 'success');
          return this.props.history.push(
            `/campsdata/detail/${result.campdataDetail._id}`
          );
        } else {
          return this.props.doSnack('Kamp bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewCamp = (e) => {
    e.preventDefault();
    this.props.history.push('/campsdata/new', {
      campCopy: { ...this.props.campDetail },
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'camps' + '_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  updateNewCategory = (val) => {
    this.setState({
      ...this.state,
      newCategory: {
        category: val,
      },
    });
  };

  onCustomUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw kamp data niet op te slaan.',
      'success'
    );
    this.props.doUpdateCamp('customImg', files[0].location);
  };

  addNewCategory = (e) => {
    e.preventDefault();
    const { campCategories } = this.props;
    const cat = this.state.newCategory.category;
    for (let c = 0; c < campCategories.length; c++) {
      if (campCategories[c].category === cat) {
        return swal('Probleem', 'Deze category bestaat al', 'error');
      }
    }
    this.props
      .doAddCampCategory(cat)
      .then(() => {
        this.setState(
          {
            newCategory: {},
          },
          () => {
            this.props.doSnack('Categorie toegevoegd', 'success');
            this.props.doListCampCategories();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  changeFeature = (feature) => {
    let theFeature;
    for (let f = 0; f < this.props.productFeatures.length; f++) {
      if (this.props.productFeatures[f].title === feature) {
        theFeature = this.props.productFeatures[f];
        break;
      }
    }
    this.setState({
      newFeature: {
        feature,
        featureValues: theFeature.values,
      },
    });
  };

  changeFeatureValue = (selectedFeatureValue) => {
    this.setState({
      newFeature: {
        ...this.state.newFeature,
        selectedFeatureValue,
      },
    });
  };

  addNewFeature = () => {
    const { features } = this.props.campDetail;
    const { newFeature } = this.state;
    let newValues = [];
    if (features && Object.keys(features).length) {
      const featureKeys = Object.keys(features);
      for (let f = 0; f < featureKeys.length; f++) {
        if (featureKeys[f] === newFeature.feature) {
          const currentValues = features[newFeature.feature];
          if (typeof currentValues === 'string') {
            if (currentValues === newFeature.selectedFeatureValue) {
              return this.props.doSnack('Deze waarde werd al toegevoegd');
            }
            newValues.push(currentValues, newFeature.selectedFeatureValue);
          } else {
            if (currentValues.indexOf(newFeature.selectedFeatureValue) > -1) {
              return this.props.doSnack('Deze waarde werd al toegevoegd');
            }
            newValues = [...currentValues];
            newValues.push(newFeature.selectedFeatureValue);
          }
        }
      }
    }
    let newFeatures = features ? { ...features } : {};
    newFeatures[newFeature.feature] = newValues.length
      ? newValues
      : [newFeature.selectedFeatureValue];
    this.props.doUpdateCamp('features', newFeatures);
    this.setState({
      newFeature: {},
    });
    this.props.doSnack(
      'Attribuut toegevoegd. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  removeFeature = (feature) => {
    let { features } = this.props.campDetail;
    delete features[feature];
    this.props.doUpdateCamp('features', features);
    this.props.doSnack(
      'Attribuut verwijderd. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  removeFromWaitingList = (index) => {
    const { campDetail } = this.props;
    let waitingList = [...campDetail.waitingList];
    waitingList.splice(index, 1);
    this.props.doUpdateCamp('waitingList', waitingList);
    this.props.doSnack(
      'E-mailadres verwijderd van wachtlijst. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  loadRegistrations = () => {
    const { id } = this.state;
    this.props
      .doListRegistrations(id)
      .then((res) => {
        this.setState({
          ...this.state,
          campRegistrations: res.registrations,
        });
      })
      .catch(() => {
        this.props.doSnack('Probleem bij ophalen inschrijvingen');
      });
  };

  sendWaitingListMail = () => {
    const { campDetail } = this.props;
    swal({
      title: 'Mail verzenden',
      text: 'Bent u zeker dat u de wachtlijst mail wil verzenden?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doMailWaitinglist(campDetail._id)
          .then(() => {
            this.props.doSnack('Mail verzonden', 'success');
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verzenden');
          });
      }
    });
  };

  createEmailList = () => {
    const { campRegistrations } = this.state;
    if (!campRegistrations?.length) {
      this.setState({
        ...this.state,
        regEmailList: '',
      });
    }
    let list = '';
    campRegistrations.forEach((regis) => {
      list += list.length ? '; ' : '';
      list += regis.email;
    });
    this.setState({
      ...this.state,
      regEmailList: list,
    });
  };

  render() {
    const { campDetail, productFeatures, campsv2 } = this.props;
    const { newFeature, campRegistrations, regEmailList } = this.state;

    return (
      <div>
        <div className="campdetail-root">
          <div className="campdetail-root__header">
            <button className="btn btn-primary" onClick={() => this.saveCamp()}>
              Sla gegevens op
            </button>
          </div>
          <div className="campdetail-root__form">
            <FormBlock title={`Details Kamp Data`}>
              <form>
                <CustomForm>
                  {campDetail._id && (
                    <button
                      className="btn btn-warning"
                      onClick={(e) => this.copyToNewCamp(e)}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Kopieer naar nieuw kamp
                    </button>
                  )}
                  <label htmlFor="camp">Kamp</label>
                  <select
                    name="camp"
                    id="camp"
                    value={campDetail.campid || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('campid', e.currentTarget.value)
                    }
                    disabled={campDetail._id}
                  >
                    <option value="">Kies een kamp</option>
                    {campsv2.map((camp, c) => (
                      <option key={c} value={camp._id}>
                        {camp.title}
                      </option>
                    ))}
                  </select>
                  <label>
                    Subtitel Homepage * (10/06 - 18/06, 6 - 10 jaar, Sporthal
                    Den Draver) *
                  </label>
                  <input
                    id="subTitleHome"
                    name="subTitleHome"
                    value={campDetail.subTitleHome || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('subTitleHome', e.target.value)
                    }
                  />
                  <label>Extra info</label>
                  <textarea
                    id="extraInfo"
                    name="extraInfo"
                    value={campDetail.extraInfo || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('extraInfo', e.target.value)
                    }
                    rows="4"
                  />
                  <label>Prijs *</label>
                  <input
                    id="price"
                    name="price"
                    value={campDetail.price || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('price', e.target.value)
                    }
                  />
                  <label htmlFor="campHalfDay">
                    <input
                      id="campHalfDay"
                      name="campHalfDay"
                      type="checkbox"
                      checked={campDetail.campHalfDay ? 'checked' : ''}
                      onChange={(e) =>
                        this.props.doUpdateCamp(
                          'campHalfDay',
                          !campDetail.campHalfDay
                        )
                      }
                      style={{ marginRight: 10 }}
                    />
                    Dit kamp kan doorgaan voor halve dagen
                  </label>
                  {campDetail.campHalfDay && (
                    <>
                      <label>Prijs Halve Dag*</label>
                      <input
                        id="priceHalfDay"
                        name="priceHalfDay"
                        value={campDetail.priceHalfDay || ''}
                        onChange={(e) =>
                          this.props.doUpdateCamp(
                            'priceHalfDay',
                            e.target.value
                          )
                        }
                      />
                    </>
                  )}
                  <label>Prijs met UITPAS</label>
                  <input
                    id="priceUitpas"
                    name="priceUitpas"
                    value={campDetail.priceUitpas || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('priceUitpas', e.target.value)
                    }
                  />
                  <label>Adres *</label>
                  <input
                    id="address"
                    name="address"
                    value={campDetail.address || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('address', e.target.value)
                    }
                  />
                  <label>Datum van *</label>
                  <input
                    type="date"
                    id="dateFrom"
                    name="dateFrom"
                    value={campDetail.dateFrom || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('dateFrom', e.target.value)
                    }
                  />
                  <label>Datum tot *</label>
                  <input
                    type="date"
                    id="dateTo"
                    name="dateTo"
                    value={campDetail.dateTo || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('dateTo', e.target.value)
                    }
                  />
                  <label>Uren</label>
                  <textarea
                    id="hours"
                    name="hours"
                    value={campDetail.hours || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('hours', e.target.value)
                    }
                    rows="4"
                  />
                  <label>
                    Geboortejaar vanaf (numeriek in jaar vb. 2009) *
                  </label>
                  <input
                    id="ageFrom"
                    name="ageFrom"
                    value={campDetail.ageFrom || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp(
                        'ageFrom',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label>
                    Geboortejaar tot en met (numeriek in jaar vb. 2014) *
                  </label>
                  <input
                    id="ageTo"
                    name="ageTo"
                    value={campDetail.ageTo || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('ageTo', parseInt(e.target.value))
                    }
                  />
                  <label>Maximum aantal inschrijvingen *</label>
                  <input
                    id="maxRegistrations"
                    name="maxRegistrations"
                    value={campDetail.maxRegistrations || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp(
                        'maxRegistrations',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label>
                    Locatie Latitude, Longitude * (vb. 51.2445585, 4.5554557 -
                    gewoon copy paste Google Maps)
                  </label>
                  <input
                    id="latLng"
                    name="latLng"
                    value={campDetail.latLng || ''}
                    onChange={(e) =>
                      this.props.doUpdateCamp('latLng', e.target.value)
                    }
                  />
                </CustomForm>
              </form>
            </FormBlock>
            {campDetail._id && (
              <FormBlock title="Kamp gegevens">
                <form>
                  <CustomForm>
                    <label htmlFor="unavailable">
                      <input
                        id="unavailable"
                        name="unavailable"
                        type="checkbox"
                        checked={campDetail.unavailable ? 'checked' : ''}
                        onChange={(e) =>
                          this.props.doUpdateCamp(
                            'unavailable',
                            !campDetail.unavailable
                          )
                        }
                        style={{ marginRight: 10 }}
                      />
                      Verborgen
                    </label>
                    <label htmlFor="withSleepover">
                      <input
                        id="withSleepover"
                        name="withSleepover"
                        type="checkbox"
                        checked={campDetail.withSleepover ? 'checked' : ''}
                        onChange={(e) =>
                          this.props.doUpdateCamp(
                            'withSleepover',
                            !campDetail.withSleepover
                          )
                        }
                        style={{ marginRight: 10 }}
                      />
                      Met overnachting
                    </label>
                    <label htmlFor="externalRegistration">
                      <input
                        id="externalRegistration"
                        name="externalRegistration"
                        type="checkbox"
                        checked={
                          campDetail.externalRegistration ? 'checked' : ''
                        }
                        onChange={(e) =>
                          this.props.doUpdateCamp(
                            'externalRegistration',
                            !campDetail.externalRegistration
                          )
                        }
                        style={{ marginRight: 10 }}
                      />
                      Externe inschrijving
                    </label>
                    {campDetail.externalRegistration && (
                      <>
                        <label>URL naar externe inschrijvingspagina</label>
                        <input
                          id="externalRegistrationLink"
                          name="externalRegistrationLink"
                          value={campDetail.externalRegistrationLink || ''}
                          onChange={(e) =>
                            this.props.doUpdateCamp(
                              'externalRegistrationLink',
                              e.target.value
                            )
                          }
                        />
                      </>
                    )}
                    {campDetail.externalRegistration && (
                      <label htmlFor="externalRegistrationFull">
                        <input
                          id="externalRegistrationFull"
                          name="externalRegistrationFull"
                          type="checkbox"
                          checked={
                            campDetail.externalRegistrationFull ? 'checked' : ''
                          }
                          onChange={(e) =>
                            this.props.doUpdateCamp(
                              'externalRegistrationFull',
                              !campDetail.externalRegistrationFull
                            )
                          }
                          style={{ marginRight: 10 }}
                        />
                        Externe inschrijving is VOLZET
                      </label>
                    )}
                    <label>
                      Aangepaste schuine hoofdafbeelding voor overzicht
                    </label>
                    {campDetail.customImg && (
                      <img
                        src={campDetail.customImg}
                        width="320px"
                        alt="main"
                      />
                    )}
                    <FileUpload
                      onUploadComplete={(files) => this.onCustomUpload(files)}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg"
                      prefix="camps"
                    />
                    <label>PDF voor bevestiging inschrijving</label>
                    {campDetail.registrationPDF && (
                      <div>
                        <a
                          href={campDetail.registrationPDF}
                          target="_blank"
                          rel="noreferrer"
                          alt="pdf"
                        >
                          Open PDF
                        </a>
                      </div>
                    )}
                    <FileUpload
                      onUploadComplete={this.onPDFUpload}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept=".pdf"
                      prefix="camps"
                    />
                  </CustomForm>
                </form>
              </FormBlock>
            )}
            {campDetail._id && (
              <FormBlock title="Attributen">
                <CustomForm>
                  <label>Kamp attributen</label>
                  <table
                    border="1"
                    width="100%"
                    className="campdetail-root__form__table"
                  >
                    <tbody>
                      {(campDetail.features &&
                        Object.keys(campDetail.features).length &&
                        Object.keys(campDetail.features).map((feature, f) => (
                          <tr key={f}>
                            <td>{feature}</td>
                            <td>
                              {typeof campDetail.features[feature] === 'string'
                                ? campDetail.features[feature]
                                : campDetail.features[feature].join()}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <button
                                onClick={() => this.removeFeature(feature)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))) ||
                        null}
                    </tbody>
                  </table>
                  <label htmlFor="features">Beschikbare attributen</label>
                  <select
                    name="features"
                    value={newFeature.feature || ''}
                    onChange={(e) => this.changeFeature(e.currentTarget.value)}
                  >
                    <option value="">Kies attribuut</option>
                    {productFeatures.map((feature, f) => (
                      <option key={f} value={feature.title}>
                        {feature.title}
                      </option>
                    ))}
                  </select>
                  {newFeature.feature && newFeature.featureValues && (
                    <select
                      name="featureValues"
                      value={newFeature.selectedFeatureValue}
                      onChange={(e) =>
                        this.changeFeatureValue(e.currentTarget.value)
                      }
                    >
                      <option value="">Selecteer attribuut waarde</option>
                      {newFeature.featureValues.map((featureVal, fa) => (
                        <option key={fa} value={featureVal.value}>
                          {featureVal.value}
                        </option>
                      ))}
                    </select>
                  )}
                  {newFeature.feature && newFeature.selectedFeatureValue && (
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.addNewFeature()}
                    >
                      Voeg attribuut toe
                    </button>
                  )}
                </CustomForm>
              </FormBlock>
            )}
            {campDetail.waitingList && campDetail.waitingList.length && (
              <FormBlock title="Wachtlijst">
                <CustomForm>
                  <button
                    className="btn btn-warning"
                    onClick={() => this.sendWaitingListMail()}
                  >
                    Stuur mail naar wachtlijst met vrije plaats
                  </button>
                  <label>Ingeschreven op wachtlijst</label>
                  <table
                    border="1"
                    width="100%"
                    className="campdetail-root__form__table"
                  >
                    <tbody>
                      {campDetail.waitingList.map((waiting, w) => (
                        <tr key={w}>
                          <td>{waiting}</td>
                          <td style={{ textAlign: 'right' }}>
                            <button
                              onClick={() => this.removeFromWaitingList(w)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CustomForm>
              </FormBlock>
            )}
            {campRegistrations?.length ? (
              <FormBlock title="Inschrijvingen">
                <CustomForm>
                  <button
                    className="btn btn-warning"
                    onClick={() => this.createEmailList()}
                  >
                    Maak gescheiden lijst van alle e-mailadressen
                  </button>
                  {regEmailList?.length ? (
                    <div
                      style={{
                        width: '100%',
                        paddingTop: 16,
                        paddingBottom: 16,
                        fontSize: 11,
                      }}
                    >
                      {regEmailList}
                    </div>
                  ) : null}
                  <label>Ingeschreven</label>
                  <table
                    border="1"
                    width="100%"
                    className="campdetail-root__form__table"
                  >
                    <tbody>
                      {campRegistrations.map((regis, r) => (
                        <tr key={r}>
                          <td>{regis.email}</td>
                          <td>{regis.participantFirstName}</td>
                          <td>{regis.participantName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CustomForm>
              </FormBlock>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campDetail: state.camps.campdataDetail,
    campsv2: state.camps.campsv2,
    productFeatures: state.productFeatures.productFeatures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListCampsV2: () => {
      dispatch(listCampsV2());
    },
    doListProductFeatures: () => {
      return dispatch(listFeatures());
    },
    doSaveCamp: (theCamp) => {
      return dispatch(saveCampData(theCamp));
    },
    doMailWaitinglist: (id) => {
      return dispatch(mailWaitingList(id));
    },
    doResetCamp: (copy) => {
      dispatch(resetCampData(copy));
    },
    doShowCamp: (id) => {
      return dispatch(showCampData(id));
    },
    doUpdateCamp: (field, val) => {
      dispatch(updateCampData(field, val));
    },
    doListRegistrations: (id) => {
      return dispatch(listRegistrations(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampDetail);
