import {
  UPDATE_ZSTEACHER,
  SET_ZSTEACHER,
  SET_ZSTEACHERS,
  LIST_ZSTEACHERS,
  SAVE_ZSTEACHER,
  LOAD_ZSTEACHER,
  SAVE_ZSTEACHERSSCHOOL,
  SET_ZSTEACHERSSCHOOLS,
  LIST_ZSTEACHERSSCHOOLS,
  REMOVE_ZSTEACHER,
} from '../actions/zsteachers/types';

export const initialState = {
  isFetching: false,
  teachers: [],
  teacherDetail: {},
  groups: [],
};

const teachers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ZSTEACHER:
      return {
        ...state,
        teacherDetail: {
          ...state.teacherDetail,
          [action.field]: action.value,
          passwordChanged:
            action.field === 'password'
              ? true
              : state.teacherDetail.passwordChanged,
          emailChanged:
            action.field === 'email' ? true : state.teacherDetail.emailChanged,
          teachernameChanged:
            action.field === 'teachername'
              ? true
              : state.teacherDetail.teachernameChanged,
        },
      };
    case SET_ZSTEACHER:
      return {
        ...state,
        isFetching: false,
        teacherDetail: action.teacherDetail,
      };
    case SET_ZSTEACHERS:
      return {
        ...state,
        isFetching: false,
        teachers: action.teachers,
      };
    case SET_ZSTEACHERSSCHOOLS:
      return {
        ...state,
        isFetching: false,
        groups: action.groups,
      };
    case SAVE_ZSTEACHER:
      return { ...state, isFetching: action.isFetching || false };
    case REMOVE_ZSTEACHER:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_ZSTEACHERSSCHOOL:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSTEACHERS:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSTEACHERSSCHOOLS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_ZSTEACHER:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default teachers;
