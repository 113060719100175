export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANIES = 'SET_COMPANIES';
export const LIST_COMPANIES = 'LIST_COMPANIES';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const LIST_GAMES = 'LIST_GAMES';
export const SET_GAMES = 'SET_GAMES';
export const SET_GAME = 'SET_GAME';
export const UPDATE_GAME = 'UPDATE_GAME';
export const LOAD_GAME = 'LOAD_GAME';
export const SAVE_GAME = 'SAVE_GAME';
export const REMOVE_GAME = 'REMOVE_GAME';
export const LIST_APP_REGISTRATIONS = 'LIST_APP_REGISTRATIONS';
export const SET_APP_REGISTRATIONS = 'SET_APP_REGISTRATIONS';
export const REMOVE_APP_REGISTRTATION = 'REMOVE_APP_REGISTRTATION';
export const LIST_APP_SCORES = 'LIST_APP_SCORES';
export const SET_APP_SCORES = 'SET_APP_SCORES';
export const REMOVE_APP_SCORES = 'REMOVE_APP_SCORES';
export const LOAD_REGISTRATION = 'LOAD_REGISTRATION';
export const SET_REGISTRATION = 'SET_REGISTRATION';
export const UPLOAD_APP_REGISTRTATION = 'UPLOAD_APP_REGISTRTATION';
