import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import {
  faArrowDown,
  faArrowUp,
  faCopy,
  faEdit,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  listGames,
  resetCompany,
  resetCompanyRegistrations,
  resetCompanyScores,
  saveCompany,
  showCompany,
  updateCompany,
} from '../../../redux/actions/appjeppa';
import { hexToRGB, searchSafe } from '../../../utils/text';
import { array_move } from '../../../utils/array';

class AppCompanyDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newGroup: {},
    groupForCopy: undefined,
    gamesForCopy: undefined,
  };

  componentDidMount = () => {
    this.props.doListGames();
    if (this.state.id) {
      this.props.doShowCompany(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen bedrijf');
        this.props.history.push('/appjeppacompanies');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.companyCopy
      ) {
        let newCopy = { ...this.props.location.state.companyCopy };
        newCopy.copyFromCompany = JSON.parse(JSON.stringify(newCopy._id));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetCompany(newCopy);
      } else {
        this.props.doResetCompany();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw bedrijf niet op te slaan.',
      'success'
    );
    this.props.doUpdateCompany(
      mobile ? 'bannerImgMobile' : 'bannerImg',
      files[0].location
    );
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw bedrijf niet op te slaan.',
      'success'
    );
    this.props.doUpdateCompany('video', files[0].location);
  };

  onLogoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw bedrijf niet op te slaan.',
      'success'
    );
    this.props.doUpdateCompany('logo', files[0].location);
  };

  onPDFUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw bedrijf niet op te slaan.',
      'success'
    );
    this.props.doUpdateCompany('registrationPDF', files[0].location);
  };

  onCarrouselImgsComplete = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw bedrijf niet op te slaan.',
      'success'
    );
    const imgs = files.map((file) => file.location);
    this.props.doUpdateCompany('carrouselImgs', imgs);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateCompany('longDescriptionState', editorState);
  };

  onWelcomeEditorStateChange = (editorState) => {
    this.props.doUpdateCompany('welcomeDescriptionState', editorState);
  };

  saveCompany = () => {
    const {
      _id,
      name,
      title,
      info,
      url,
      dateFrom,
      loginDateFrom,
      minimumPeopleInTeam,
      maximumPeopleInTeam,
      maxRegistrations,
      groups,
      primaryColor,
      secondaryColor,
    } = this.props.companyDetail;
    if (
      name?.length < 2 ||
      title?.length < 2 ||
      info?.length < 2 ||
      url?.length < 3 ||
      dateFrom?.length < 2 ||
      loginDateFrom?.length < 2 ||
      !minimumPeopleInTeam ||
      !maximumPeopleInTeam ||
      !maxRegistrations ||
      !groups?.length ||
      !primaryColor?.length ||
      !secondaryColor?.length
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld. Controleer de verplichte velden met *.',
        'error'
      );
    }
    const theDateFrom = new Date(dateFrom);
    const theLoginDateFrom = new Date(loginDateFrom);
    if (theDateFrom.getTime() > theLoginDateFrom.getTime()) {
      return this.props.doSnack(
        'De startdatum inschrijvingen moet hoger of gelijk zijn dat den datum van het evenement.',
        'error'
      );
    }
    if (parseInt(minimumPeopleInTeam) > parseInt(maximumPeopleInTeam)) {
      return this.props.doSnack(
        'Maximum aantal personen in team moet groter of gelijk zijn dan het minimum aantal personen.',
        'error'
      );
    }
    this.props
      .doSaveCompany()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Bedrijf toegevoegd', 'success');
          return this.props.history.push(
            `/appjeppacompanies/detail/${result.company._id}`
          );
        } else {
          return this.props.doSnack('Bedrijf bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewCompany = (e) => {
    e.preventDefault();
    const { name, url, ...rest } = this.props.companyDetail;
    this.props.history.push('/appjeppacompanies/new', {
      companyCopy: { ...rest },
    });
  };

  resetRegistrations = (e) => {
    e.preventDefault();
    const { companyDetail } = this.props;
    swal({
      title: 'Reset',
      text: 'Bent u zeker dat u alle inschrijvingen en scores wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doResetCompanyRegistrations(companyDetail._id)
          .then(() => {
            this.props.doSnack('Reset compleet', 'success');
          })
          .catch(() => {
            this.props.doSnack('Probleem bij reset');
          });
      }
    });
  };

  resetScores = (e) => {
    e.preventDefault();
    const { companyDetail } = this.props;
    swal({
      title: 'Reset',
      text: 'Bent u zeker dat u alle scores wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doResetCompanyScores(companyDetail._id)
          .then(() => {
            this.props.doSnack('Reset compleet', 'success');
          })
          .catch(() => {
            this.props.doSnack('Probleem bij reset');
          });
      }
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'camps' + '_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  updateNewCategory = (val) => {
    this.setState({
      ...this.state,
      newCategory: {
        category: val,
      },
    });
  };

  addNewCategory = (e) => {
    e.preventDefault();
    const { campCategories } = this.props;
    const cat = this.state.newCategory.category;
    for (let c = 0; c < campCategories.length; c++) {
      if (campCategories[c].category === cat) {
        return swal('Probleem', 'Deze category bestaat al', 'error');
      }
    }
    this.props
      .doAddCampCategory(cat)
      .then(() => {
        this.setState(
          {
            newCategory: {},
          },
          () => {
            this.props.doSnack('Categorie toegevoegd', 'success');
            this.props.doListCampCategories();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  addNewGroup = () => {
    const { groups } = this.props.companyDetail;
    const { newGroup } = this.state;
    const theGroupName = newGroup.group.trim().toUpperCase();
    const groupSafe = searchSafe(theGroupName, true);
    if (groups?.length) {
      if (groups.indexOf(theGroupName) > -1) {
        return swal('Probleem', 'Groepsnaam bestaat reeds', 'error');
      }
      for (let g = 0; g < groups?.length; g++) {
        if (groupSafe === searchSafe(groups[g], true)) {
          return swal(
            'Probleem',
            'Groepsnaam lijkt te hard op een reeds bestaande groep. Kies een andere naam.',
            'error'
          );
        }
      }
    }

    let newGroups = groups?.length ? groups : [];
    newGroups.push(theGroupName);
    this.props.doUpdateCompany('groups', newGroups);
    this.setState({
      newGroup: {},
    });
    this.props.doSnack(
      'Groep toegevoegd. Vergeet niet op te slaan.',
      'success'
    );
  };

  copyGroupGames = (e, group) => {
    e.preventDefault();
    const { companyDetail } = this.props;
    const currentGames = companyDetail[`games-${searchSafe(group, true)}`];
    this.setState({
      groupForCopy: group,
      gamesForCopy: currentGames,
    });
    this.props.doSnack(
      'Spelen in kopieermodus. Plak ze bij een andere groep.',
      'success'
    );
  };

  pasteGroupGames = (e, group) => {
    e.preventDefault();
    const { gamesForCopy } = this.state;
    this.setState(
      {
        groupForCopy: undefined,
        gamesForCopy: undefined,
      },
      () => {
        this.props.doUpdateCompany(
          `games-${searchSafe(group, true)}`,
          gamesForCopy
        );
        this.props.doSnack(
          'Spelen geplakt bij deze groep. Vergeet item niet op te slaan.',
          'success'
        );
      }
    );
  };

  removeGroup = (e, groupIndex) => {
    e.preventDefault();
    let { groups } = this.props.companyDetail;
    groups.splice(groupIndex, 1);
    const groupName = groups[groupIndex];
    this.props.doUpdateCompany('groups', groups).then(() => {
      this.props.doSnack(
        'Groep verwijderd. Vergeet item niet op te slaan.',
        'success'
      );
      console.log('group deleted');
      if (this.props.companyDetail[`games-${searchSafe(groupName, true)}`]) {
        this.props.doUpdateCompany(
          `games-${searchSafe(groupName, true)}`,
          null
        );
        console.log('games deleted');
      }
    });
  };

  addSelectedGame = (game, group) => {
    const { companyDetail } = this.props;
    let newGames;
    if (companyDetail[`games-${searchSafe(group, true)}`]) {
      newGames = companyDetail[`games-${searchSafe(group, true)}`];
    }
    newGames = newGames?.length ? newGames : [];
    newGames.push(game);
    this.props.doUpdateCompany(`games-${searchSafe(group, true)}`, newGames);
    this.props.doSnack('Spel toegevoegd. Vergeet niet op te slaan.', 'success');
  };

  isGameSelected = (game, group) => {
    let { companyDetail } = this.props;
    let currentGames;
    if (companyDetail[`games-${searchSafe(group, true)}`]) {
      currentGames = companyDetail[`games-${searchSafe(group, true)}`];
    }
    currentGames = currentGames?.length ? currentGames : [];
    if (!currentGames?.length) {
      return false;
    }
    for (let g = 0; g < currentGames.length; g++) {
      if (currentGames[g]._id === game._id) {
        return true;
      }
    }
    return false;
  };

  gameUp = (e, gameIndex, group) => {
    const { companyDetail } = this.props;
    const games = companyDetail[`games-${searchSafe(group, true)}`];
    e.preventDefault();
    const newGames = array_move(games, gameIndex, gameIndex + 1);
    this.props.doUpdateCompany(`games-${searchSafe(group, true)}`, newGames);
  };

  gameDown = (e, gameIndex, group) => {
    const { companyDetail } = this.props;
    const games = companyDetail[`games-${searchSafe(group, true)}`];
    e.preventDefault();
    const newGames = array_move(games, gameIndex, gameIndex - 1);
    this.props.doUpdateCompany(`games-${searchSafe(group, true)}`, newGames);
  };

  removeGame = (e, gameIndex, group) => {
    e.preventDefault();
    let { companyDetail } = this.props;
    const games = companyDetail[`games-${searchSafe(group, true)}`];
    games.splice(gameIndex, 1);
    this.props.doUpdateCompany(`games-${searchSafe(group, true)}`, games);
    this.props.doSnack(
      'Spel verwijderd. Vergeet item niet op te slaan.',
      'success'
    );
  };

  removeFromWaitingList = (index) => {
    const { companyDetail } = this.props;
    let waitingList = [...companyDetail.waitingList];
    waitingList.splice(index, 1);
    this.props.doUpdateCompany('waitingList', waitingList);
    this.props.doSnack(
      'E-mailadres verwijderd van wachtlijst. Vergeet kamp niet op te slaan.',
      'success'
    );
  };

  sendWaitingListMail = () => {
    const { companyDetail } = this.props;
    swal({
      title: 'Mail verzenden',
      text: 'Bent u zeker dat u de wachtlijst mail wil verzenden?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doMailWaitinglist(companyDetail._id)
          .then(() => {
            this.props.doSnack('Mail verzonden', 'success');
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verzenden');
          });
      }
    });
  };

  getPickerColors = (extra) => {
    let colors = [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ];
    if (extra?.length) {
      for (var i = 0; i < extra.length; i++) {
        if (extra[i]?.length) {
          colors.unshift(hexToRGB(extra[i]).toString());
        }
      }
    }
    return colors;
  };

  editGame = (game) => {
    return this.props.history.push(`/appjeppagames/detail/${game._id}`);
  };

  setURL = () => {
    const { companyDetail } = this.props;
    if (!companyDetail.url || !companyDetail.url.length) {
      this.props.doUpdateCompany(
        'url',
        searchSafe(companyDetail.name, false, true).toLowerCase()
      );
    }
  };

  render() {
    const { companyDetail, appGames } = this.props;
    const { newGroup, groupForCopy, gamesForCopy } = this.state;

    const longDescriptionState =
      companyDetail && companyDetail.longDescriptionState
        ? companyDetail.longDescriptionState
        : companyDetail.longDescription
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(companyDetail.longDescription)
            )
          )
        : EditorState.createEmpty();

    const welcomeDescriptionState =
      companyDetail && companyDetail.welcomeDescriptionState
        ? companyDetail.welcomeDescriptionState
        : companyDetail.welcomeText
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(companyDetail.welcomeText)
            )
          )
        : EditorState.createEmpty();

    return (
      <div>
        <div className="companyDetail-root">
          <div className="companyDetail-root__header">
            <button
              className="btn btn-primary"
              onClick={() => this.saveCompany()}
            >
              Sla gegevens op
            </button>
          </div>
          <div className="companyDetail-root__header"></div>
          <div className="companyDetail-root__form">
            <FormBlock
              title={`Details ${
                companyDetail.copyFromLanguage
                  ? `(Kopie van ${companyDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  {companyDetail._id && (
                    <button
                      className="btn btn-warning"
                      onClick={(e) => this.copyToNewCompany(e)}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Maak copy van bedrijf
                    </button>
                  )}
                  {companyDetail._id && (
                    <button
                      className="btn btn-o btn-danger"
                      onClick={(e) => this.resetRegistrations(e)}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Reset inschrijvingen en
                      scores
                    </button>
                  )}
                  {companyDetail._id && (
                    <button
                      className="btn btn-o btn-danger"
                      onClick={(e) => this.resetScores(e)}
                    >
                      <FontAwesomeIcon icon={faCopy} /> Reset enkel scores
                    </button>
                  )}
                  <label>Bedrijfsnaam * (vb. Orange)</label>
                  <input
                    id="name"
                    name="name"
                    value={companyDetail.name || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany('name', e.target.value)
                    }
                    onBlur={() => this.setURL()}
                  />
                  <label>
                    URL * (geen spaties of speciale tekens) (wordt gebruikt voor
                    de opbouw van de url voor QR code vb.
                    app.jeppasport.com/orange
                  </label>
                  <input
                    id="url"
                    name="url"
                    value={companyDetail.url || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany('url', e.target.value)
                    }
                  />

                  {companyDetail.url ? (
                    <a
                      href={`http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=https://app.jeppasport.com/${companyDetail.url}&qzone=1&margin=0&size=1000x1000&ecc=L`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`http://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=https://app.jeppasport.com/${companyDetail.url}&qzone=1&margin=0&size=200x200&ecc=L`}
                        alt="qr code"
                        width="200"
                        height="200"
                      />
                    </a>
                  ) : null}

                  <label>
                    Titel voor de app * MAX 30 karakters (vb. Company Cup Orange
                    )
                  </label>
                  <input
                    id="title"
                    name="title"
                    value={companyDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'title',
                        e.target.value.substring(0, 30)
                      )
                    }
                  />
                  <label htmlFor="info">
                    Korte info (wordt getoond onder de titel, max 2 zinnen)
                  </label>
                  <textarea
                    id="info"
                    name="info"
                    value={companyDetail.info || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany('info', e.target.value)
                    }
                    rows={4}
                  />
                  <label htmlFor="name">Lange beschrijving</label>
                  <Editor
                    editorState={longDescriptionState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="wysiwygeditor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        alt: { present: true, mandatory: false },
                        previewImage: true,
                        inputAccept: 'image/jpeg,image/jpg,image/png',
                      },
                    }}
                  />

                  <label htmlFor="welcomeText">
                    Extra blok voor verwelkoming in app
                  </label>
                  {companyDetail?.primaryColor?.trim().length !== 7 ||
                  companyDetail?.secondaryColor?.trim().length !== 7 ? (
                    <p>
                      Gelieve eerst de primaire en secundaire kleuren correct in
                      te vullen.
                    </p>
                  ) : null}
                  {companyDetail?.primaryColor?.trim().length === 7 &&
                  companyDetail?.secondaryColor?.trim().length === 7 ? (
                    <Editor
                      editorState={welcomeDescriptionState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="wysiwygeditor"
                      onEditorStateChange={this.onWelcomeEditorStateChange}
                      toolbar={{
                        colorPicker: {
                          colors: this.getPickerColors([
                            companyDetail?.primaryColor,
                            companyDetail?.secondaryColor,
                          ]),
                        },
                      }}
                    />
                  ) : null}
                  <label htmlFor="mailInfo">
                    Extra info voor bevestigingsmail (houd dit kort)
                  </label>
                  <textarea
                    id="mailInfo"
                    name="mailInfo"
                    value={companyDetail.mailInfo || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany('mailInfo', e.target.value)
                    }
                    rows={4}
                  />
                  <label>
                    Inschrijvingen open vanaf welke datum * (vb. 15/07/2023)
                  </label>
                  <input
                    type="date"
                    id="dateFrom"
                    name="dateFrom"
                    value={companyDetail.dateFrom || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany('dateFrom', e.target.value)
                    }
                  />
                  <label htmlFor="disableRegistrations">
                    <input
                      id="disableRegistrations"
                      name="disableRegistrations"
                      type="checkbox"
                      checked={
                        companyDetail.disableRegistrations ? 'checked' : ''
                      }
                      onChange={(e) =>
                        this.props.doUpdateCompany(
                          'disableRegistrations',
                          !companyDetail.disableRegistrations
                        )
                      }
                      style={{ marginRight: 10 }}
                    />
                    Inschrijvingen stopzetten
                  </label>
                  <label>
                    Aanmelden in app vanaf welke datum * (vb. 15/07/2023)
                  </label>
                  <input
                    type="date"
                    id="loginDateFrom"
                    name="loginDateFrom"
                    value={companyDetail.loginDateFrom || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'loginDateFrom',
                        e.target.value
                      )
                    }
                  />
                  <label>Maximum aantal teams *</label>
                  <input
                    id="maxRegistrations"
                    name="maxRegistrations"
                    value={companyDetail.maxRegistrations || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'maxRegistrations',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label>Minimum aantal personen per team *</label>
                  <input
                    id="minimumPeopleInTeam"
                    name="minimumPeopleInTeam"
                    value={companyDetail.minimumPeopleInTeam || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'minimumPeopleInTeam',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label>Maximum aantal personen per team *</label>
                  <input
                    id="maximumPeopleInTeam"
                    name="maximumPeopleInTeam"
                    value={companyDetail.maximumPeopleInTeam || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'maximumPeopleInTeam',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label htmlFor="multipleRegistrationsSameEmail">
                    <input
                      id="multipleRegistrationsSameEmail"
                      name="multipleRegistrationsSameEmail"
                      type="checkbox"
                      checked={
                        companyDetail.multipleRegistrationsSameEmail
                          ? 'checked'
                          : ''
                      }
                      onChange={(e) =>
                        this.props.doUpdateCompany(
                          'multipleRegistrationsSameEmail',
                          !companyDetail.multipleRegistrationsSameEmail
                        )
                      }
                      style={{ marginRight: 10 }}
                    />
                    Meerdere inschrijvingen met zelfde e-mailadres toestaan
                  </label>
                  <label htmlFor="disableLeaderboard">
                    <input
                      id="disableLeaderboard"
                      name="disableLeaderboard"
                      type="checkbox"
                      checked={
                        companyDetail.disableLeaderboard ? 'checked' : ''
                      }
                      onChange={(e) =>
                        this.props.doUpdateCompany(
                          'disableLeaderboard',
                          !companyDetail.disableLeaderboard
                        )
                      }
                      style={{ marginRight: 10 }}
                    />
                    Ranglijsten niet beschikbaar voor app
                  </label>
                </CustomForm>
              </form>
            </FormBlock>
            <FormBlock title="Groepen*">
              <form>
                <CustomForm>
                  <label>
                    Beschikbare groepen in app (minstens 1 toevoegen!)
                  </label>
                  <table
                    border="1"
                    width="100%"
                    className="companydetail-root__form__table"
                  >
                    <tbody>
                      {(companyDetail?.groups?.length &&
                        companyDetail.groups.map((group, g) => (
                          <tr key={g}>
                            <td>{group}</td>
                            <td style={{ textAlign: 'right' }}>
                              <button onClick={(e) => this.removeGroup(e, g)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))) ||
                        null}
                    </tbody>
                  </table>
                  <label>Nieuwe groep (minimum 3 karakters)</label>
                  <input
                    id="groupname"
                    name="groupname"
                    value={newGroup.group || ''}
                    onChange={(e) =>
                      this.setState({
                        ...this.state,
                        newGroup: {
                          group: e.target.value,
                        },
                      })
                    }
                  />
                  {newGroup?.group?.length > 3 && (
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.addNewGroup()}
                    >
                      Voeg groep toe
                    </button>
                  )}
                </CustomForm>
              </form>
            </FormBlock>
            {companyDetail._id && (
              <FormBlock title="Spelen">
                <CustomForm>
                  <label>Geselecteerde spelen per groep</label>

                  {companyDetail?.groups?.length
                    ? companyDetail.groups.map((group) => (
                        <div
                          key={group}
                          style={{
                            border: '1px solid grey',
                            padding: 12,
                            backgroundColor: '#efefef',
                            marginBottom: 16,
                          }}
                        >
                          <p style={{ marginBottom: 0 }}>
                            Geselecteerde spelen van groep {group}
                          </p>
                          {companyDetail[`games-${searchSafe(group, true)}`]
                            ?.length ? (
                            <button
                              className="btn btn-warning"
                              style={{ marginBottom: 6 }}
                              onClick={(e) => this.copyGroupGames(e, group)}
                            >
                              Kopieer alle spelen van deze groep
                            </button>
                          ) : null}

                          {gamesForCopy && groupForCopy !== group ? (
                            <button
                              className="btn btn-info"
                              style={{ marginBottom: 6 }}
                              onClick={(e) => this.pasteGroupGames(e, group)}
                            >
                              Plak spelen van groep {groupForCopy}
                            </button>
                          ) : null}

                          <table
                            border="1"
                            width="100%"
                            className="companyDetail-root__form__table"
                          >
                            <tbody>
                              {companyDetail[`games-${searchSafe(group, true)}`]
                                ?.length
                                ? companyDetail[
                                    `games-${searchSafe(group, true)}`
                                  ]?.map((game, g) => (
                                    <tr key={g}>
                                      <td>{game.titleCMS}</td>
                                      <td style={{ textAlign: 'right' }}>
                                        <button
                                          onClick={(e) => this.editGame(game)}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        {g !== 0 ? (
                                          <button
                                            onClick={(e) =>
                                              this.gameDown(e, g, group)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faArrowUp} />
                                          </button>
                                        ) : null}
                                        {g !==
                                        companyDetail.games?.length - 1 ? (
                                          <button
                                            onClick={(e) =>
                                              this.gameUp(e, g, group)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faArrowDown}
                                            />
                                          </button>
                                        ) : null}
                                        <button
                                          onClick={(e) =>
                                            this.removeGame(e, g, group)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </table>
                          <label htmlFor="features">
                            Beschikbare spelen groep - {group}
                          </label>
                          <table
                            border="1"
                            width="100%"
                            className="companyDetail-root__form__table"
                          >
                            <tbody>
                              {(appGames?.length &&
                                appGames.map((appGame, g) => {
                                  if (!this.isGameSelected(appGame, group)) {
                                    return (
                                      <tr key={g}>
                                        <td>{appGame.titleCMS}</td>
                                        <td style={{ textAlign: 'right' }}>
                                          <button
                                            onClick={(e) =>
                                              this.editGame(appGame)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                          </button>
                                          <button
                                            onClick={() =>
                                              this.addSelectedGame(
                                                appGame,
                                                group
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faPlus} />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })) ||
                                null}
                            </tbody>
                          </table>
                        </div>
                      ))
                    : null}
                </CustomForm>
              </FormBlock>
            )}
            <FormBlock title="Look & feel">
              <form>
                <CustomForm>
                  <label htmlFor="primaryColor">
                    Kleurcode Primaire kleur in HEX waarde (vb. #059ef1) *
                  </label>
                  <input
                    id="primaryColor"
                    name="primaryColor"
                    value={companyDetail.primaryColor || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'primaryColor',
                        e.target.value.trim()
                      )
                    }
                  />
                  {companyDetail?.primaryColor?.length ? (
                    <p style={{ color: companyDetail.primaryColor }}>
                      Dit is een voorbeeld van tekst in de kleur
                    </p>
                  ) : null}
                  <label htmlFor="secondaryColor">
                    Kleurcode Secundaire kleur in HEX waarde (vb. #e3d64b) *
                  </label>
                  <input
                    id="secondaryColor"
                    name="secondaryColor"
                    value={companyDetail.secondaryColor || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'secondaryColor',
                        e.target.value.trim()
                      )
                    }
                  />
                  {companyDetail?.secondaryColor?.length ? (
                    <p style={{ color: companyDetail.secondaryColor }}>
                      Dit is een voorbeeld van tekst in de kleur
                    </p>
                  ) : null}
                  <label htmlFor="titleColor">
                    Specifieke kleur voor titels in HEX waarde (ipv standaard
                    wit) (vb. #000000) *
                  </label>
                  <input
                    id="titleColor"
                    name="titleColor"
                    value={companyDetail.titleColor || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'titleColor',
                        e.target.value.trim()
                      )
                    }
                  />
                  {companyDetail?.titleColor?.length ? (
                    <div
                      style={{
                        backgroundColor: companyDetail.primaryColor || 'grey',
                        color: companyDetail.titleColor || 'white',
                        padding: 40,
                      }}
                    >
                      Voorbeeld van tekst zoals titels worden getoond
                    </div>
                  ) : null}
                  <label htmlFor="buttonColor">
                    Specifieke kleur voor tekst op knoppen in HEX waarde (ipv
                    standaard wit) (vb. #000000) *
                  </label>
                  <input
                    id="buttonColor"
                    name="buttonColor"
                    value={companyDetail.buttonColor || ''}
                    onChange={(e) =>
                      this.props.doUpdateCompany(
                        'buttonColor',
                        e.target.value.trim()
                      )
                    }
                  />
                  {companyDetail?.buttonColor?.length ? (
                    <button
                      style={{
                        backgroundColor: companyDetail.secondaryColor || 'grey',
                        color: companyDetail.buttonColor || 'white',
                        padding: '8px 14px',
                        borderRadius: 12,
                        border: 'none',
                      }}
                    >
                      Voorbeeldknop
                    </button>
                  ) : null}
                  <label>
                    Logo van het bedrijf (gelieve formaat te respecteren 600 x
                    458 met 72dpi)
                  </label>
                  {companyDetail.logo && (
                    <img src={companyDetail.logo} width="120px" alt="logo" />
                  )}
                  <FileUpload
                    onUploadComplete={(files) => this.onLogoUpload(files)}
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept="image/png, image/jpeg, .svg, .webp"
                    prefix="appcompanies"
                  />
                  <label>
                    Banner afbeelding op hoofdpagina (2000 x 880 is ideaal
                    formaat)
                  </label>
                  {companyDetail.bannerImg && (
                    <div>
                      <img
                        src={companyDetail.bannerImg}
                        width="auto"
                        style={{ maxHeight: 320, marginBottom: 6 }}
                        alt="head"
                      />
                    </div>
                  )}
                  <FileUpload
                    onUploadComplete={this.onHeaderUpload}
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept="image/png, image/jpeg, .svg, .webp"
                    prefix="appcompanies"
                  />
                  <label>
                    Banner video op hoofdpagina (in plaats van een afbeelding)
                    enkel in mp4 en max 2MB
                  </label>
                  {companyDetail.video && (
                    <video width="100%" height="300" controls>
                      <source src={companyDetail.video} type="video/mp4" />
                    </video>
                  )}
                  <FileUpload
                    onUploadComplete={this.onVideoUpload}
                    onError={() =>
                      this.props.doSnack('Probleem met upload', 'error')
                    }
                    bucketName={bucketName}
                    awsAccessKey={awsAccessKey}
                    awsSecretKey={awsSecretKey}
                    multiple={false}
                    accept=".mp4"
                    prefix="appcompanies"
                    maxSizeKB={2000}
                  />
                </CustomForm>
              </form>
            </FormBlock>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyDetail: state.appjeppa.companyDetail,
    appGames: state.appjeppa.games,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doResetCompanyRegistrations: (id) => {
      return dispatch(resetCompanyRegistrations(id));
    },
    doResetCompanyScores: (id) => {
      return dispatch(resetCompanyScores(id));
    },
    doListGames: () => {
      return dispatch(listGames());
    },
    doSaveCompany: () => {
      return dispatch(saveCompany());
    },
    doResetCompany: (copy) => {
      dispatch(resetCompany(copy));
    },
    doShowCompany: (id) => {
      return dispatch(showCompany(id));
    },
    doUpdateCompany: (field, val) => {
      return dispatch(updateCompany(field, val));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCompanyDetail);
