import {
  UPDATE_ZSUSER,
  SET_ZSUSER,
  SET_ZSUSERS,
  LIST_ZSUSERS,
  SAVE_ZSUSER,
  LOAD_ZSUSER,
  SAVE_ZSUSERSSCHOOL,
  SET_ZSUSERSSCHOOLS,
  LIST_ZSUSERSSCHOOLS,
  REMOVE_ZSUSER,
} from '../actions/zsusers/types';

export const initialState = {
  isFetching: false,
  users: [],
  userDetail: {},
  schools: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ZSUSER:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          [action.field]: action.value,
          passwordChanged:
            action.field === 'password'
              ? true
              : state.userDetail.passwordChanged,
          emailChanged:
            action.field === 'email' ? true : state.userDetail.emailChanged,
          usernameChanged:
            action.field === 'username'
              ? true
              : state.userDetail.usernameChanged,
        },
      };
    case SET_ZSUSER:
      return {
        ...state,
        isFetching: false,
        userDetail: action.userDetail,
      };
    case SET_ZSUSERS:
      return {
        ...state,
        isFetching: false,
        users: action.users,
      };
    case SET_ZSUSERSSCHOOLS:
      return {
        ...state,
        isFetching: false,
        schools: action.schools,
      };
    case SAVE_ZSUSER:
      return { ...state, isFetching: action.isFetching || false };
    case REMOVE_ZSUSER:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_ZSUSERSSCHOOL:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSUSERS:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_ZSUSERSSCHOOLS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_ZSUSER:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default users;
