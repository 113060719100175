import React, { Component } from 'react';
import FormBlock from '../../../components/FormBlock';
import './style.scss';
import CustomForm from '../../../components/CustomForm';
import { showSnack } from '../../../redux/actions/app';
import { connect } from 'react-redux';
import FileUpload from '../../../components/FileUpload';
import { awsAccessKey, awsSecretKey, bucketName } from '../../../config';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import S3FileUpload from 'react-s3';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { searchSafe } from '../../../utils/text';
import {
  addActivityCategory,
  addActivityValue,
  listActivityCategories,
  listActivityValues,
  resetActivity,
  saveActivity,
  showActivity,
  updateActivity,
} from '../../../redux/actions/teambuilding';

class TeambuildingActivityDetail extends Component {
  state = {
    id:
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null,
    newCategory: {},
    newValue: {},
    useACategory: {},
    useAValue: {},
  };

  componentDidMount = () => {
    this.props.doListActivityCategories();
    this.props.doListActivityValues();
    if (this.state.id) {
      this.props.doShowActivity(this.state.id).catch(() => {
        this.props.doSnack('Probleem bij openen activiteit');
        this.props.history.push('/teambuildingactivities');
      });
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.activityCopy
      ) {
        let newCopy = { ...this.props.location.state.activityCopy };
        newCopy.copyFromLanguage = JSON.parse(JSON.stringify(newCopy.language));
        delete newCopy.language;
        delete newCopy._id;
        this.props.doResetActivity(newCopy);
      } else {
        this.props.doResetActivity();
      }
    }
  };

  onHeaderUpload = (files, mobile) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw activiteit niet op te slaan.',
      'success'
    );
    this.props.doUpdateActivity(
      mobile ? 'bannerImgMobile' : 'bannerImg',
      files[0].location
    );
  };

  onVideoUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw activiteit niet op te slaan.',
      'success'
    );
    this.props.doUpdateActivity('video', files[0].location);
  };

  onMainUpload = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw activiteit niet op te slaan.',
      'success'
    );
    this.props.doUpdateActivity('mainImg', files[0].location);
  };

  onCarrouselImgsComplete = (files) => {
    this.props.doSnack(
      'Upload gelukt. Vergeet uw activiteit niet op te slaan.',
      'success'
    );
    const imgs = files.map((file) => file.location);
    this.props.doUpdateActivity('carrouselImgs', imgs);
  };

  onEditorStateChange = (editorState) => {
    this.props.doUpdateActivity('longDescriptionState', editorState);
  };

  saveActivity = () => {
    const { _id, title, info, url } = this.props.activityDetail;
    if (
      !title ||
      title.length < 2 ||
      !info ||
      info.length < 2 ||
      !url ||
      url.length < 3
    ) {
      return this.props.doSnack(
        'Niet alle velden werden correct ingevuld.',
        'error'
      );
    }
    this.props
      .doSaveActivity()
      .then((result) => {
        if (!_id) {
          this.props.doSnack('Activiteit toegevoegd', 'success');
          return this.props.history.push(
            `/teambuildingactivities/detail/${result.activity._id}`
          );
        } else {
          return this.props.doSnack('Activiteit bijgewerkt', 'success');
        }
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  copyToNewLanguage = (e) => {
    e.preventDefault();
    this.props.history.push('/teambuildingactivities/new', {
      activityCopy: { ...this.props.activityDetail },
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const config = {
        bucketName: bucketName,
        region: 'eu-central-1',
        accessKeyId: awsAccessKey,
        secretAccessKey: awsSecretKey,
        dirName: 'activities' + '_' + Date.now(),
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          return resolve({
            data: {
              link: data.location,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };

  setURL = () => {
    const { activityDetail } = this.props;
    if (!activityDetail.url || !activityDetail.url.length) {
      this.props.doUpdateActivity(
        'url',
        searchSafe(activityDetail.title, false, true).toLowerCase()
      );
    }
  };

  updateNewCategory = (val) => {
    this.setState({
      ...this.state,
      newCategory: {
        category: val,
      },
    });
  };

  updateUseCategory = (val) => {
    this.setState({
      ...this.state,
      useACategory: {
        category: val,
      },
    });
  };

  useNewCategory = (e) => {
    e.preventDefault();
    const { activityDetail } = this.props;
    const { useACategory } = this.state;
    let categories =
      activityDetail.categories && activityDetail.categories.length
        ? [...activityDetail.categories]
        : [];
    if (categories.indexOf(useACategory.category) >= 0) {
      return this.props.doSnack('Categorie reeds toegevoegd.');
    } else {
      categories.push(useACategory.category);
      this.props.doUpdateActivity('categories', categories);
      this.props.doSnack(
        'Categorie werd toegevoegd. Vergeet niet op te slaan',
        'success'
      );
      this.setState({
        useACategory: {},
      });
    }
  };

  addNewCategory = (e) => {
    e.preventDefault();
    const { activityCategories } = this.props;
    const cat = this.state.newCategory.category;
    for (let c = 0; c < activityCategories.length; c++) {
      if (activityCategories[c].category === cat) {
        return swal('Probleem', 'Deze categorie bestaat al', 'error');
      }
    }
    this.props
      .doAddActivityCategory(cat)
      .then(() => {
        this.setState(
          {
            newCategory: {},
          },
          () => {
            this.props.doSnack('Categorie toegevoegd', 'success');
            this.props.doListActivityCategories();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  removeCategory = (e, index) => {
    e.preventDefault();
    const { activityDetail } = this.props;
    let categories = [...activityDetail.categories];
    categories.splice(index, 1);
    this.props.doUpdateActivity('categories', categories);
    this.props.doSnack(
      'Categorie verwijderd. Vergeet niet op te slaan.',
      'success'
    );
  };

  updateNewValue = (val) => {
    this.setState({
      ...this.state,
      newValue: {
        avalue: val,
      },
    });
  };

  updateUseValue = (val) => {
    this.setState({
      ...this.state,
      useAValue: {
        avalue: val,
      },
    });
  };

  useNewValue = (e) => {
    e.preventDefault();
    const { activityDetail } = this.props;
    const { useAValue } = this.state;
    let values =
      activityDetail.values && activityDetail.values.length
        ? [...activityDetail.values]
        : [];
    if (values.indexOf(useAValue.avalue) >= 0) {
      return this.props.doSnack('Categorie reeds toegevoegd.');
    } else {
      values.push(useAValue.avalue);
      this.props.doUpdateActivity('values', values);
      this.props.doSnack(
        'Waarde werd toegevoegd. Vergeet niet op te slaan',
        'success'
      );
      this.setState({
        useAValue: {},
      });
    }
  };

  addNewValue = (e) => {
    e.preventDefault();
    const { activityValues } = this.props;
    const val = this.state.newValue.avalue;
    for (let c = 0; c < activityValues.length; c++) {
      if (activityValues[c].avalue === val) {
        return swal('Probleem', 'Deze waarde bestaat al', 'error');
      }
    }
    this.props
      .doAddActivityValue(val)
      .then(() => {
        this.setState(
          {
            newValue: {},
          },
          () => {
            this.props.doSnack('Waarde toegevoegd', 'success');
            this.props.doListActivityValues();
          }
        );
      })
      .catch((e) => {
        if (e && e.error && typeof e.error === 'string') {
          this.props.doSnack(e.error, 'error');
        } else {
          this.props.doSnack('Probleem bij opslaan', 'error');
        }
      });
  };

  removeValue = (e, index) => {
    e.preventDefault();
    const { activityDetail } = this.props;
    let values = [...activityDetail.values];
    values.splice(index, 1);
    this.props.doUpdateActivity('values', values);
    this.props.doSnack(
      'Waarde verwijderd. Vergeet niet op te slaan.',
      'success'
    );
  };

  render() {
    const { useACategory, newCategory, useAValue, newValue } = this.state;
    const { activityDetail, activityCategories, activityValues } = this.props;

    const longDescriptionState =
      activityDetail && activityDetail.longDescriptionState
        ? activityDetail.longDescriptionState
        : activityDetail.longDescription
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(activityDetail.longDescription)
            )
          )
        : EditorState.createEmpty();

    return (
      <div>
        <div className="activitydetail-root">
          <div className="activitydetail-root__header">
            <button
              className="btn btn-primary"
              onClick={() => this.saveActivity()}
            >
              Sla gegevens op
            </button>
          </div>
          <div className="activitydetail-root__form">
            <FormBlock
              title={`Details ${
                activityDetail.copyFromLanguage
                  ? `(Kopie van ${activityDetail.copyFromLanguage})`
                  : ''
              }`}
            >
              <form>
                <CustomForm>
                  <label>Titel *</label>
                  <input
                    id="title"
                    name="title"
                    value={activityDetail.title || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('title', e.target.value)
                    }
                    onBlur={() => this.setURL()}
                  />
                  <label>Meta Titel</label>
                  <input
                    id="metaTitle"
                    name="metaTitle"
                    value={activityDetail.metaTitle || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('metaTitle', e.target.value)
                    }
                  />
                  <label htmlFor="metaDescription">Meta Omschrijving</label>
                  <textarea
                    id="metaDescription"
                    name="metaDescription"
                    value={activityDetail.metaDescription || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity(
                        'metaDescription',
                        e.target.value
                      )
                    }
                    rows={4}
                  />
                  <label htmlFor="info">Korte info</label>
                  <textarea
                    id="info"
                    name="info"
                    value={activityDetail.info || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('info', e.target.value)
                    }
                    rows={4}
                  />
                  <label>URL * (geen spaties of speciale tekens)</label>
                  <input
                    id="url"
                    name="url"
                    value={activityDetail.url || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('url', e.target.value)
                    }
                    disabled={activityDetail._id}
                  />
                  <label>Sorteervolgorde van 1 tot ...</label>
                  <input
                    id="sortorder"
                    name="sortorder"
                    value={String(activityDetail.sortorder || '')}
                    onChange={(e) =>
                      this.props.doUpdateActivity(
                        'sortorder',
                        parseInt(e.target.value)
                      )
                    }
                  />
                  <label>
                    Aantal personen (geef in als min. 10 of max. 40)
                  </label>
                  <input
                    id="minPeople"
                    name="minPeople"
                    value={activityDetail.minPeople || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('minPeople', e.target.value)
                    }
                  />
                  <label>Benodigde tijd (Voer in als 1u of 1u-3u)</label>
                  <input
                    id="time"
                    name="time"
                    value={activityDetail.time || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('time', e.target.value)
                    }
                  />
                  <label>Intensiteit in procent (vb. 60%)</label>
                  <input
                    id="intensityperc"
                    name="intensityperc"
                    value={String(activityDetail.intensityperc || '')}
                    onChange={(e) =>
                      this.props.doUpdateActivity(
                        'intensityperc',
                        e.target.value
                      )
                    }
                  />
                  <label>Leeftijd</label>
                  <select
                    name="age"
                    value={activityDetail.age || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity('age', e.currentTarget.value)
                    }
                  >
                    <option value="">Kies een leeftijd</option>
                    <option value="Alle leeftijden">Alle leeftijden</option>
                    <option value="3-6 jaar">3-6 jaar</option>
                    <option value="6-9 jaar">6-9 jaar</option>
                    <option value="9-12 jaar">9-12 jaar</option>
                    <option value="12-18 jaar">12-18 jaar</option>
                    <option value="+18 jaar">+18 jaar</option>
                  </select>
                  <label>Indoor/Outdoor</label>
                  <select
                    name="inout"
                    value={activityDetail.inout || ''}
                    onChange={(e) =>
                      this.props.doUpdateActivity(
                        'inout',
                        e.currentTarget.value
                      )
                    }
                  >
                    <option value="">Kies een indoor of outdoor</option>
                    <option value="Indoor">Indoor</option>
                    <option value="Outdoor">Outdoor</option>
                    <option value="Indoor & Outdoor">Indoor & Outdoor</option>
                  </select>

                  {false ? (
                    <>
                      <label>Intensiteit</label>
                      <select
                        name="intensity"
                        value={activityDetail.intensity || ''}
                        onChange={(e) =>
                          this.props.doUpdateActivity(
                            'intensity',
                            e.currentTarget.value
                          )
                        }
                      >
                        <option value="">Kies een intensiteit</option>
                        <option value="Rustig">Rustig</option>
                        <option value="Gemiddeld">Gemiddeld</option>
                        <option value="Hevig">Hevig</option>
                      </select>
                    </>
                  ) : null}

                  <label style={{ marginTop: 40 }}>
                    <strong>Gekoppelde Categoriën</strong>
                  </label>
                  <table
                    border="1"
                    width="100%"
                    className="activitydetail-root__form__table"
                    style={{ marginBottom: 18 }}
                  >
                    <tbody>
                      {(activityDetail.categories &&
                        activityDetail.categories.length &&
                        activityDetail.categories.map((cat, c) => (
                          <tr key={c}>
                            <td>{cat}</td>
                            <td style={{ textAlign: 'right' }}>
                              <button
                                onClick={(e) => this.removeCategory(e, c)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))) ||
                        null}
                    </tbody>
                  </table>

                  <label style={{ marginTop: 18 }}>Categorie</label>
                  <select
                    name="category"
                    value={useACategory.category || ''}
                    onChange={(e) =>
                      this.updateUseCategory(e.currentTarget.value)
                    }
                  >
                    <option value="">Kies een categorie</option>
                    {activityCategories.map((category, c) => (
                      <option key={c} value={category.category}>
                        {category.category}
                      </option>
                    ))}
                  </select>
                  <button
                    className="btn btn-info"
                    onClick={(e) => this.useNewCategory(e)}
                    disabled={
                      !useACategory.category || !useACategory.category.length
                    }
                  >
                    Gebruik categorie
                  </button>
                  <label>Nieuwe categorie toevoegen</label>
                  <input
                    id="newCategory"
                    name="newCategory"
                    value={newCategory.category || ''}
                    onChange={(e) => this.updateNewCategory(e.target.value)}
                  />
                  <button
                    className="btn btn-warning"
                    disabled={
                      !newCategory.category || !newCategory.category.length
                    }
                    onClick={(e) => this.addNewCategory(e)}
                  >
                    Voeg categorie toe
                  </button>

                  <label style={{ marginTop: 40 }}>
                    <strong>Gekoppelde waarden</strong>
                  </label>
                  <table
                    border="1"
                    width="100%"
                    className="activitydetail-root__form__table"
                    style={{ marginBottom: 18 }}
                  >
                    <tbody>
                      {(activityDetail.values &&
                        activityDetail.values.length &&
                        activityDetail.values.map((val, v) => (
                          <tr key={v}>
                            <td>{val}</td>
                            <td style={{ textAlign: 'right' }}>
                              <button onClick={(e) => this.removeValue(e, v)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))) ||
                        null}
                    </tbody>
                  </table>

                  <label style={{ marginTop: 18 }}>Team waarden</label>
                  <select
                    name="values"
                    value={useAValue.avalue || ''}
                    onChange={(e) => this.updateUseValue(e.currentTarget.value)}
                  >
                    <option value="">Kies een waarde</option>
                    {activityValues.map((aval, v) => (
                      <option key={v} value={aval.avalue}>
                        {aval.avalue}
                      </option>
                    ))}
                  </select>
                  <button
                    className="btn btn-info"
                    onClick={(e) => this.useNewValue(e)}
                    disabled={!useAValue.avalue || !useAValue.avalue.length}
                  >
                    Gebruik waarde
                  </button>
                  <label>Nieuwe waarde toevoegen</label>
                  <input
                    id="newValue"
                    name="newValue"
                    value={newValue.avalue || ''}
                    onChange={(e) => this.updateNewValue(e.target.value)}
                  />
                  <button
                    className="btn btn-warning"
                    disabled={!newValue.avalue || !newValue.avalue.length}
                    onClick={(e) => this.addNewValue(e)}
                  >
                    Voeg waarde toe
                  </button>
                </CustomForm>
              </form>
            </FormBlock>
            {activityDetail._id && (
              <FormBlock title="Activiteit gegevens">
                <form>
                  <CustomForm>
                    <label htmlFor="name">Lange omschrijving</label>
                    <Editor
                      editorState={longDescriptionState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="wysiwygeditor"
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: this.uploadImageCallBack,
                          alt: { present: true, mandatory: false },
                          previewImage: true,
                          inputAccept: 'image/jpeg,image/jpg,image/png',
                        },
                      }}
                    />
                    <label>
                      Youtube Video ID (wat achter '/watch?v=' staat vb.
                      44KNdTRyAHo)
                    </label>
                    {activityDetail.youtubeID && (
                      <iframe
                        width="100%"
                        height="320"
                        src={`https://www.youtube.com/embed/${activityDetail.youtubeID}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                    <input
                      id="youtubeID"
                      name="youtubeID"
                      value={activityDetail.youtubeID || ''}
                      onChange={(e) =>
                        this.props.doUpdateActivity('youtubeID', e.target.value)
                      }
                    />
                    <label>Banner afbeelding detailpagina</label>
                    {activityDetail.bannerImg && (
                      <div>
                        <img
                          src={activityDetail.bannerImg}
                          width="auto"
                          style={{
                            maxHeight: 320,
                            marginBottom: 6,
                          }}
                          alt="head"
                        />
                      </div>
                    )}
                    <FileUpload
                      onUploadComplete={this.onHeaderUpload}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg, .svg, .webp"
                      prefix="activities"
                    />
                    <label>Banner afbeelding detailpagina (MOBILE)</label>
                    {activityDetail.bannerImgMobile && (
                      <div>
                        <img
                          src={activityDetail.bannerImgMobile}
                          width="auto"
                          style={{
                            maxHeight: 320,
                            marginBottom: 6,
                          }}
                          alt="head"
                        />
                      </div>
                    )}
                    <FileUpload
                      onUploadComplete={(files) =>
                        this.onHeaderUpload(files, true)
                      }
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg, .svg, .webp"
                      prefix="activities"
                    />
                    <label>Banner video detailpagina</label>
                    {activityDetail.video && (
                      <video width="100%" height="300" controls>
                        <source src={activityDetail.video} type="video/mp4" />
                      </video>
                    )}
                    <FileUpload
                      onUploadComplete={this.onVideoUpload}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept=".mp4"
                      prefix="activities"
                      maxSizeKB={2000}
                    />
                    <label htmlFor="bannerTitle">Banner Titel</label>
                    <textarea
                      id="bannerTitle"
                      name="bannerTitle"
                      value={activityDetail.bannerTitle || ''}
                      onChange={(e) =>
                        this.props.doUpdateActivity(
                          'bannerTitle',
                          e.target.value
                        )
                      }
                      rows={3}
                    />
                    <label>Schuine hoofdafbeelding voor overzicht</label>
                    {activityDetail.mainImg && (
                      <img
                        src={activityDetail.mainImg}
                        width="320px"
                        alt="main"
                      />
                    )}
                    <FileUpload
                      onUploadComplete={(files) => this.onMainUpload(files)}
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={false}
                      accept="image/png, image/jpeg"
                      prefix="activities"
                    />
                    <label>Afbeeldingen voor carrousel</label>
                    {activityDetail.carrouselImgs &&
                      activityDetail.carrouselImgs.length && (
                        <div className="activitydetail-root__form__imgbox">
                          {activityDetail.carrouselImgs.map((img, i) => (
                            <img
                              key={i}
                              src={img}
                              width="120px"
                              height="120px"
                              style={{ margin: 10 }}
                              alt="color"
                            />
                          ))}
                        </div>
                      )}
                    <FileUpload
                      onUploadComplete={(files) =>
                        this.onCarrouselImgsComplete(files, true)
                      }
                      onError={() =>
                        this.props.doSnack('Probleem met upload', 'error')
                      }
                      bucketName={bucketName}
                      awsAccessKey={awsAccessKey}
                      awsSecretKey={awsSecretKey}
                      multiple={true}
                      accept="image/png, image/jpeg"
                      prefix="activities"
                    />
                  </CustomForm>
                </form>
              </FormBlock>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activityDetail: state.teambuilding.activityDetail,
    activityCategories: state.teambuilding.activityCategories,
    activityValues: state.teambuilding.activityValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSaveActivity: () => {
      return dispatch(saveActivity());
    },
    doResetActivity: () => {
      dispatch(resetActivity());
    },
    doShowActivity: (id) => {
      return dispatch(showActivity(id));
    },
    doUpdateActivity: (field, val) => {
      dispatch(updateActivity(field, val));
    },
    doListActivityCategories: () => {
      dispatch(listActivityCategories());
    },
    doListActivityValues: () => {
      dispatch(listActivityValues());
    },
    doAddActivityCategory: (category) => {
      return dispatch(addActivityCategory(category));
    },
    doAddActivityValue: (avalue) => {
      return dispatch(addActivityValue(avalue));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeambuildingActivityDetail);
