import {
  faCheck,
  faCheckSquare,
  faDownload,
  faEdit,
  faEnvelope,
  faPlus,
  faQuestionCircle,
  faSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import {
  getNestedPropsFromString,
  removePropFromObject,
} from '../../utils/object';
import { format } from 'date-fns';
import ScaleLoader from 'react-spinners/ScaleLoader';
import alasql from 'alasql';
import swal from 'sweetalert';
import CSVReader from '../CSVReader';
alasql['private'].externalXlsxLib = require('xlsjs');

export const selectTextFromValue = (value, listOfValues) => {
  if (!listOfValues || !listOfValues.length) {
    return value;
  }
  for (var i = 0; i < listOfValues.length; i++) {
    if (listOfValues[i].value === value) {
      return listOfValues[i].text;
    }
  }
  return value;
};

export const tableFilter = (items, activeFilters) => {
  if (items?.length) {
    let filteredItems = items;
    let filteredProps = Object.keys(activeFilters);
    for (let f = 0; f < filteredProps.length; f++) {
      let theProp = filteredProps[f];
      const propPars = theProp.split('---');
      const realProp = propPars[0];
      const value = activeFilters[theProp];
      filteredItems = filteredItems.filter((item) => {
        const nestedVal = getNestedPropsFromString(realProp, item);
        return nestedVal !== null && nestedVal !== undefined
          ? new RegExp(
              (value !== null && value !== undefined ? value : '').toString(),
              'i'
            ).test(
              (nestedVal !== null && nestedVal !== undefined
                ? nestedVal
                : ''
              ).toString()
            )
          : false;
      });
    }
    return filteredItems;
  }
  return items;
};

export const tableSort = (sortTo, items) => {
  let sortToMinus = sortTo && sortTo.charAt(0) === '-' ? true : false;
  let realSort = sortToMinus ? sortTo.replace('-', '') : sortTo;
  if (items && items.length) {
    const itemsSorted = items.sort((a, b) => {
      const itemA = getNestedPropsFromString(realSort, a)
        ? getNestedPropsFromString(realSort, a)
        : 0;
      const itemB = getNestedPropsFromString(realSort, b)
        ? getNestedPropsFromString(realSort, b)
        : 0;
      if (itemA < itemB) {
        return sortToMinus ? 1 : -1;
      } else if (itemA > itemB) {
        return sortToMinus ? -1 : 1;
      } else {
        return 0;
      }
    });
    return itemsSorted;
  }
};

const StyledTable = ({
  title,
  fields,
  bodyItems,
  addNew,
  addNewLink,
  actions,
  onEdit,
  onDelete,
  onMail,
  onVerify,
  showPager,
  page,
  limit,
  total,
  nextPage,
  previousPage,
  fetching,
  sortOptions,
  onSort,
  filter,
  onFilter,
  filtersActive,
  xlsDownload,
  csvUpload,
  onUpload,
  csvUploading,
  xlsFields,
  selectable,
  changeSelectedItems,
  xlsDownloadSplitFileProp,
  xlsFilter,
  xlsFilterProp,
  xlsFilterLabel,
  xlsMatchFilterProp,
}) => {
  const [xlsSelectedFilterValue, setXlsSelectedFilterValue] = useState();

  const performAction = (action, deal) => {
    switch (action) {
      case 'edit':
        onEdit(deal);
        break;
      case 'verify':
        onVerify(deal);
        break;
      case 'delete':
        onDelete(deal);
        break;
      case 'mail':
        onMail(deal);
        break;
      default:
        break;
    }
  };

  const getActionIcon = (action) => {
    switch (action) {
      case 'edit':
        return faEdit;
      case 'delete':
        return faTrash;
      case 'mail':
        return faEnvelope;
      case 'verify':
        return faCheck;
      default:
        return faQuestionCircle;
    }
  };

  const downloadToExcel = (currentLines) => {
    let files = [];
    if (bodyItems && bodyItems.length) {
      let itemsSorted = bodyItems;
      if (xlsDownloadSplitFileProp && !currentLines) {
        itemsSorted = bodyItems.sort((a, b) => {
          const itemA =
            xlsDownloadSplitFileProp.indexOf('.') > -1
              ? getNestedPropsFromString(xlsDownloadSplitFileProp, a, true)
              : a[xlsDownloadSplitFileProp]
              ? a[xlsDownloadSplitFileProp]
              : 0;
          const itemB =
            xlsDownloadSplitFileProp.indexOf('.') > -1
              ? getNestedPropsFromString(xlsDownloadSplitFileProp, b, true)
              : b[xlsDownloadSplitFileProp]
              ? b[xlsDownloadSplitFileProp]
              : 0;
          if (itemA < itemB) {
            return -1;
          } else if (itemA > itemB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      let itemForSplit;
      let tableData = [];
      for (let b = 0; b < bodyItems.length; b++) {
        let item = {};
        let bodyItem = bodyItems[b];
        let canAdd = true;
        if (
          xlsSelectedFilterValue &&
          xlsSelectedFilterValue.length &&
          xlsMatchFilterProp
        ) {
          var bodyItemValue = getNestedPropsFromString(
            xlsMatchFilterProp,
            bodyItem,
            true
          );
          if (xlsSelectedFilterValue !== bodyItemValue) {
            canAdd = false;
          }
        }
        if (canAdd) {
          let itemForSplitBodyItem =
            xlsDownloadSplitFileProp && !currentLines
              ? xlsDownloadSplitFileProp.indexOf('.') > -1
                ? getNestedPropsFromString(
                    xlsDownloadSplitFileProp,
                    bodyItem,
                    true
                  )
                : bodyItem[xlsDownloadSplitFileProp]
              : 'download_file_';
          if (!itemForSplit || itemForSplit !== itemForSplitBodyItem) {
            if (tableData.length) {
              files.push({
                item: itemForSplit,
                data: [...tableData],
              });
              tableData = [];
            }
            itemForSplit = itemForSplitBodyItem;
          }
          const excelFields = xlsFields || fields;
          for (let f = 0; f < excelFields.length; f++) {
            let field = excelFields[f];
            item[field.title] = field.boolAsCheck
              ? bodyItem[field.prop]
                ? 'ja'
                : 'nee'
              : field.asDateTime
              ? format(bodyItem[field.prop], field.dateFormat)
              : field.selectValue
              ? selectTextFromValue(bodyItem[field.prop], field.selectValue)
              : field.prop.indexOf('.') > -1
              ? getNestedPropsFromString(field.prop, bodyItem, true)
              : bodyItem[field.prop];
          }
          tableData.push(item);
        }
      }
      if (tableData.length) {
        files.push({
          item: itemForSplit,
          data: [...tableData],
        });
        tableData = [];
      }
      if (files && files.length) {
        let fileIndex = 0;
        const downloadAFile = () => {
          setTimeout(() => {
            const currentFile = files[fileIndex];
            alasql(
              'SELECT * INTO XLSX("' +
                currentFile.item +
                '_' +
                Date.now() +
                '.xlsx",{headers:true}) FROM ?',
              [currentFile.data]
            );
            fileIndex++;
            if (fileIndex < files.length) {
              downloadAFile();
            } else {
              swal('Download', 'Download afgerond', 'success');
            }
          }, 1000);
        };
        downloadAFile();
      }
    }
  };

  const getSelectFilterActiveValue = (prop, values, activeFilters) => {
    let activeValue;
    if (!values?.length) {
      return null;
    }
    let activeFilterProps = Object.getOwnPropertyNames(activeFilters);
    if (!activeFilterProps?.length) {
      return null;
    }
    for (let v = 0; v < values.length; v++) {
      const valueProp = `${prop}---${values[v]}`;
      for (let ac = 0; ac < activeFilterProps.length; ac++) {
        const theActiveProp = activeFilterProps[ac];
        if (theActiveProp === valueProp) {
          activeValue = values[v];
          break;
        }
      }
    }
    return activeValue;
  };

  const getTextFilterActiveValue = (prop, activeFilters) => {
    let activeFilterProps = Object.getOwnPropertyNames(activeFilters);
    if (!activeFilterProps?.length) {
      return null;
    }
    if (activeFilters[prop] || getNestedPropsFromString(prop, activeFilters)) {
      return (
        activeFilters[prop] || getNestedPropsFromString(prop, activeFilters)
      );
    }
    return null;
  };

  const getCheckBoxFilterActiveValue = (prop, activeFilters, inverse) => {
    let activeFilterProps = Object.getOwnPropertyNames(activeFilters);
    if (!activeFilterProps?.length) {
      return false;
    }
    if (
      activeFilters[prop] !== undefined ||
      getNestedPropsFromString(prop, activeFilters) !== null
    ) {
      const nval =
        activeFilters[prop] || getNestedPropsFromString(prop, activeFilters);
      return inverse ? !nval : nval;
    }
    return false;
  };

  return (
    <div className="styledtable-root">
      <div className="styledtable-root__head">
        <div>
          {title} ({(bodyItems && bodyItems.length) || 0})
        </div>
        {fetching && (
          <ScaleLoader size={45} color={'#16326e'} loading={fetching} />
        )}
        <div>
          {csvUpload && onUpload ? (
            <div className="btn btn-warning">
              {csvUploading ? (
                <ScaleLoader color="black" />
              ) : (
                <CSVReader onCSVRead={onUpload} />
              )}
            </div>
          ) : null}
          {xlsDownload && xlsFilter && xlsFilterProp && xlsFilterLabel && (
            <select
              style={{ padding: '6px 8px', marginRight: 10 }}
              value={xlsSelectedFilterValue || ''}
              onChange={(e) => setXlsSelectedFilterValue(e.currentTarget.value)}
            >
              <option value="">{xlsFilterLabel}</option>
              {(xlsFilter &&
                xlsFilter.length &&
                xlsFilter.map((filter, f) => (
                  <option value={filter[xlsFilterProp]} key={f}>
                    {filter[xlsFilterProp]}
                  </option>
                ))) ||
                null}
            </select>
          )}
          {xlsDownload && xlsFilterProp && (
            <button
              className="btn btn-warning"
              onClick={() => downloadToExcel()}
            >
              <FontAwesomeIcon icon={faDownload} />{' '}
              {xlsFilterLabel || 'Download'}
            </button>
          )}
          {xlsDownload && (
            <button
              className="btn btn-warning"
              style={{ marginLeft: 8 }}
              onClick={() => downloadToExcel(true)}
            >
              <FontAwesomeIcon icon={faDownload} /> Huidige resultaat
            </button>
          )}
          {addNew && addNewLink && (
            <NavLink to={addNewLink}>
              <button className="btn btn-primary">
                <FontAwesomeIcon icon={faPlus} /> {addNew}
              </button>
            </NavLink>
          )}
        </div>
      </div>
      {(sortOptions && sortOptions.length && (
        <div className="styledtable-root__sort">
          <span>Sorteer op</span>
          <select onChange={(event) => onSort(event.currentTarget.value)}>
            {sortOptions.map((option, o) => (
              <option key={o} value={option.sort}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      )) ||
        null}
      {(filter && filter.length && (
        <div className="styledtable-root__filter">
          <span>Filter op</span>
          {filter.map((filterItem, f) => (
            <div key={f}>
              {filterItem.type === 'checkbox' ? (
                <label>
                  <input
                    type="checkbox"
                    checked={
                      getCheckBoxFilterActiveValue(
                        filterItem.prop,
                        filtersActive,
                        filterItem.inverseCheck
                      ) || false
                    }
                    onChange={(event) => {
                      onFilter(
                        filterItem.type,
                        filterItem.prop,
                        event.currentTarget.checked
                      );
                    }}
                  />
                  {filterItem.name}
                </label>
              ) : null}
              {filterItem.type === 'text' ? (
                <input
                  type="text"
                  style={{ width: 160 }}
                  placeholder={filterItem.name}
                  value={
                    getTextFilterActiveValue(filterItem.prop, filtersActive) ||
                    ''
                  }
                  onChange={(event) =>
                    onFilter(
                      filterItem.type,
                      filterItem.prop,
                      event.target.value
                    )
                  }
                />
              ) : null}
              {filterItem.type === 'select' ? (
                <select
                  value={
                    getSelectFilterActiveValue(
                      filterItem.prop,
                      filterItem.value,
                      filtersActive
                    ) || ''
                  }
                  onChange={(event) =>
                    onFilter(
                      filterItem.type,
                      filterItem.prop,
                      event.currentTarget.value,
                      getSelectFilterActiveValue(
                        filterItem.prop,
                        filterItem.value,
                        filtersActive
                      ) !== event.currentTarget.value
                        ? event.currentTarget.value
                        : false,
                      true
                    )
                  }
                >
                  <option value="">{filterItem.name}</option>
                  {filterItem.value.map((val, v) => (
                    <option value={val} key={v}>
                      {val}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          ))}
        </div>
      )) ||
        null}
      <div className="styledtable-root__tholder">
        <table className="styledtable-root__table">
          <thead>
            <tr>
              {selectable && <td width="40">&nbsp;</td>}
              {fields.map((field, f) => (
                <td key={f}>{field.title || 'noname'}</td>
              ))}
              {(actions && actions.length && <td>&nbsp;</td>) || null}
            </tr>
          </thead>
          <tbody>
            {bodyItems.map((bodyItem, b) => {
              return (
                <tr key={b}>
                  {selectable && (
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          changeSelectedItems(
                            bodyItem._id,
                            e.currentTarget.checked
                          )
                        }
                        checked={bodyItem.selected ? 'checked' : false}
                      />
                    </td>
                  )}
                  {fields.map((field, f) => (
                    <td key={f} className={f % 2 === 0 ? 'even' : null}>
                      {field.boolAsCheck ? (
                        <span>
                          <FontAwesomeIcon
                            icon={
                              bodyItem[field.prop] ? faCheckSquare : faSquare
                            }
                            style={{
                              color: bodyItem[field.prop] ? 'green' : 'black',
                            }}
                          />
                        </span>
                      ) : field.asDateTime ? (
                        format(bodyItem[field.prop], field.dateFormat)
                      ) : field.selectValue ? (
                        selectTextFromValue(
                          bodyItem[field.prop],
                          field.selectValue
                        )
                      ) : (
                        <span>
                          {field.prop.indexOf('.') > -1
                            ? getNestedPropsFromString(
                                field.prop,
                                bodyItem,
                                true
                              )
                            : bodyItem[field.prop]}
                        </span>
                      )}
                    </td>
                  ))}
                  {(actions && actions.length && (
                    <td className="styledtable-root__table__actions">
                      {actions.map((action, a) => (
                        <button
                          key={a}
                          onClick={() => performAction(action, bodyItem)}
                          className="styledtable-root__table__actions__btn"
                        >
                          <FontAwesomeIcon icon={getActionIcon(action)} />
                        </button>
                      ))}
                    </td>
                  )) ||
                    null}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={fields.length}>
                <p>
                  Aantal : {bodyItems.length}{' '}
                  {showPager && (
                    <span>
                      / {total} | Pagina {page + 1} van{' '}
                      {Math.ceil(total / limit)}
                    </span>
                  )}
                </p>
              </td>
            </tr>
            {showPager && (
              <tr>
                <td colSpan={fields.length}>
                  <button disabled={page === 0} onClick={() => previousPage()}>
                    {'<'} Vorige
                  </button>
                  <button
                    disabled={page + 1 === Math.ceil(total / limit)}
                    onClick={() => nextPage()}
                  >
                    Volgende {'>'}
                  </button>
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default StyledTable;
